import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name layers-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTYuMDIgMTIgNS40OCAzLjEzYTEgMSAwIDAgMSAwIDEuNzRMMTMgMjEuNzRhMiAyIDAgMCAxLTIgMGwtOC41LTQuODdhMSAxIDAgMCAxIDAtMS43NEw3Ljk4IDEyIiAvPgogIDxwYXRoIGQ9Ik0xMyAxMy43NGEyIDIgMCAwIDEtMiAwTDIuNSA4Ljg3YTEgMSAwIDAgMSAwLTEuNzRMMTEgMi4yNmEyIDIgMCAwIDEgMiAwbDguNSA0Ljg3YTEgMSAwIDAgMSAwIDEuNzRaIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/layers-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Layers2: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm16.02 12 5.48 3.13a1 1 0 0 1 0 1.74L13 21.74a2 2 0 0 1-2 0l-8.5-4.87a1 1 0 0 1 0-1.74L7.98 12',
      },
    ],
    [
      'path',
      {
        d: 'M13 13.74a2 2 0 0 1-2 0L2.5 8.87a1 1 0 0 1 0-1.74L11 2.26a2 2 0 0 1 2 0l8.5 4.87a1 1 0 0 1 0 1.74Z',
      },
    ],
  ],
];

export default Layers2;
