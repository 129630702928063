import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name blinds
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyAzaDE4IiAvPgogIDxwYXRoIGQ9Ik0yMCA3SDgiIC8+CiAgPHBhdGggZD0iTTIwIDExSDgiIC8+CiAgPHBhdGggZD0iTTEwIDE5aDEwIiAvPgogIDxwYXRoIGQ9Ik04IDE1aDEyIiAvPgogIDxwYXRoIGQ9Ik00IDN2MTQiIC8+CiAgPGNpcmNsZSBjeD0iNCIgY3k9IjE5IiByPSIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/blinds
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Blinds: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 3h18' }],
    ['path', { d: 'M20 7H8' }],
    ['path', { d: 'M20 11H8' }],
    ['path', { d: 'M10 19h10' }],
    ['path', { d: 'M8 15h12' }],
    ['path', { d: 'M4 3v14' }],
    ['circle', { cx: '4', cy: '19', r: '2' }],
  ],
];

export default Blinds;
