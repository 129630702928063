import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name book-open-check
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAzSDJ2MTVoN2MxLjcgMCAzIDEuMyAzIDNWN2MwLTIuMi0xLjgtNC00LTRaIiAvPgogIDxwYXRoIGQ9Im0xNiAxMiAyIDIgNC00IiAvPgogIDxwYXRoIGQ9Ik0yMiA2VjNoLTZjLTIuMiAwLTQgMS44LTQgNHYxNGMwLTEuNyAxLjMtMyAzLTNoN3YtMi4zIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/book-open-check
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BookOpenCheck: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M8 3H2v15h7c1.7 0 3 1.3 3 3V7c0-2.2-1.8-4-4-4Z' }],
    ['path', { d: 'm16 12 2 2 4-4' }],
    ['path', { d: 'M22 6V3h-6c-2.2 0-4 1.8-4 4v14c0-1.7 1.3-3 3-3h7v-2.3' }],
  ],
];

export default BookOpenCheck;
