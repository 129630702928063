import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name recycle
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyAxOUg0LjgxNWExLjgzIDEuODMgMCAwIDEtMS41Ny0uODgxIDEuNzg1IDEuNzg1IDAgMCAxLS4wMDQtMS43ODRMNy4xOTYgOS41IiAvPgogIDxwYXRoIGQ9Ik0xMSAxOWg4LjIwM2ExLjgzIDEuODMgMCAwIDAgMS41NTYtLjg5IDEuNzg0IDEuNzg0IDAgMCAwIDAtMS43NzVsLTEuMjI2LTIuMTIiIC8+CiAgPHBhdGggZD0ibTE0IDE2LTMgMyAzIDMiIC8+CiAgPHBhdGggZD0iTTguMjkzIDEzLjU5NiA3LjE5NiA5LjUgMy4xIDEwLjU5OCIgLz4KICA8cGF0aCBkPSJtOS4zNDQgNS44MTEgMS4wOTMtMS44OTJBMS44MyAxLjgzIDAgMCAxIDExLjk4NSAzYTEuNzg0IDEuNzg0IDAgMCAxIDEuNTQ2Ljg4OGwzLjk0MyA2Ljg0MyIgLz4KICA8cGF0aCBkPSJtMTMuMzc4IDkuNjMzIDQuMDk2IDEuMDk4IDEuMDk3LTQuMDk2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/recycle
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Recycle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7 19H4.815a1.83 1.83 0 0 1-1.57-.881 1.785 1.785 0 0 1-.004-1.784L7.196 9.5' }],
    ['path', { d: 'M11 19h8.203a1.83 1.83 0 0 0 1.556-.89 1.784 1.784 0 0 0 0-1.775l-1.226-2.12' }],
    ['path', { d: 'm14 16-3 3 3 3' }],
    ['path', { d: 'M8.293 13.596 7.196 9.5 3.1 10.598' }],
    [
      'path',
      {
        d: 'm9.344 5.811 1.093-1.892A1.83 1.83 0 0 1 11.985 3a1.784 1.784 0 0 1 1.546.888l3.943 6.843',
      },
    ],
    ['path', { d: 'm13.378 9.633 4.096 1.098 1.097-4.096' }],
  ],
];

export default Recycle;
