import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name key-square
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIuNCAyLjdjLjktLjkgMi41LS45IDMuNCAwbDUuNSA1LjVjLjkuOS45IDIuNSAwIDMuNGwtMy43IDMuN2MtLjkuOS0yLjUuOS0zLjQgMEw4LjcgOS44Yy0uOS0uOS0uOS0yLjUgMC0zLjRaIiAvPgogIDxwYXRoIGQ9Im0xNCA3IDMgMyIgLz4KICA8cGF0aCBkPSJNOS40IDEwLjYgMiAxOHYzYzAgLjYuNCAxIDEgMWg0di0zaDN2LTNoMmwxLjQtMS40IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/key-square
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const KeySquare: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M12.4 2.7c.9-.9 2.5-.9 3.4 0l5.5 5.5c.9.9.9 2.5 0 3.4l-3.7 3.7c-.9.9-2.5.9-3.4 0L8.7 9.8c-.9-.9-.9-2.5 0-3.4Z',
      },
    ],
    ['path', { d: 'm14 7 3 3' }],
    ['path', { d: 'M9.4 10.6 2 18v3c0 .6.4 1 1 1h4v-3h3v-3h2l1.4-1.4' }],
  ],
];

export default KeySquare;
