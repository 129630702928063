import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name warehouse
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjIgOC4zNVYyMGEyIDIgMCAwIDEtMiAySDRhMiAyIDAgMCAxLTItMlY4LjM1QTIgMiAwIDAgMSAzLjI2IDYuNWw4LTMuMmEyIDIgMCAwIDEgMS40OCAwbDggMy4yQTIgMiAwIDAgMSAyMiA4LjM1WiIgLz4KICA8cGF0aCBkPSJNNiAxOGgxMiIgLz4KICA8cGF0aCBkPSJNNiAxNGgxMiIgLz4KICA8cmVjdCB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHg9IjYiIHk9IjEwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/warehouse
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Warehouse: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M22 8.35V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.35A2 2 0 0 1 3.26 6.5l8-3.2a2 2 0 0 1 1.48 0l8 3.2A2 2 0 0 1 22 8.35Z',
      },
    ],
    ['path', { d: 'M6 18h12' }],
    ['path', { d: 'M6 14h12' }],
    ['rect', { width: '12', height: '12', x: '6', y: '10' }],
  ],
];

export default Warehouse;
