import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name scale-3d
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxOSIgY3k9IjE5IiByPSIyIiAvPgogIDxjaXJjbGUgY3g9IjUiIGN5PSI1IiByPSIyIiAvPgogIDxwYXRoIGQ9Ik01IDd2MTJoMTIiIC8+CiAgPHBhdGggZD0ibTUgMTkgNi02IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/scale-3d
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Scale3d: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '19', cy: '19', r: '2' }],
    ['circle', { cx: '5', cy: '5', r: '2' }],
    ['path', { d: 'M5 7v12h12' }],
    ['path', { d: 'm5 19 6-6' }],
  ],
];

export default Scale3d;
