import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name refresh-cw-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgOEwxOC43NCA1Ljc0QTkuNzUgOS43NSAwIDAgMCAxMiAzQzExIDMgMTAuMDMgMy4xNiA5LjEzIDMuNDciIC8+CiAgPHBhdGggZD0iTTggMTZIM3Y1IiAvPgogIDxwYXRoIGQ9Ik0zIDEyQzMgOS41MSA0IDcuMjYgNS42NCA1LjY0IiAvPgogIDxwYXRoIGQ9Im0zIDE2IDIuMjYgMi4yNkE5Ljc1IDkuNzUgMCAwIDAgMTIgMjFjMi40OSAwIDQuNzQtMSA2LjM2LTIuNjQiIC8+CiAgPHBhdGggZD0iTTIxIDEyYzAgMS0uMTYgMS45Ny0uNDcgMi44NyIgLz4KICA8cGF0aCBkPSJNMjEgM3Y1aC01IiAvPgogIDxwYXRoIGQ9Ik0yMiAyMiAyIDIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/refresh-cw-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const RefreshCwOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 8L18.74 5.74A9.75 9.75 0 0 0 12 3C11 3 10.03 3.16 9.13 3.47' }],
    ['path', { d: 'M8 16H3v5' }],
    ['path', { d: 'M3 12C3 9.51 4 7.26 5.64 5.64' }],
    ['path', { d: 'm3 16 2.26 2.26A9.75 9.75 0 0 0 12 21c2.49 0 4.74-1 6.36-2.64' }],
    ['path', { d: 'M21 12c0 1-.16 1.97-.47 2.87' }],
    ['path', { d: 'M21 3v5h-5' }],
    ['path', { d: 'M22 22 2 2' }],
  ],
];

export default RefreshCwOff;
