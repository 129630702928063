import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name paperclip
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMjEuNDQgMTEuMDUtOS4xOSA5LjE5YTYgNiAwIDAgMS04LjQ5LTguNDlsOC41Ny04LjU3QTQgNCAwIDEgMSAxOCA4Ljg0bC04LjU5IDguNTdhMiAyIDAgMCAxLTIuODMtMi44M2w4LjQ5LTguNDgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/paperclip
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Paperclip: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48',
      },
    ],
  ],
];

export default Paperclip;
