import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name folder-sync
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSAyMEg0YTIgMiAwIDAgMS0yLTJWNWEyIDIgMCAwIDEgMi0yaDMuOWEyIDIgMCAwIDEgMS42OS45bC44MSAxLjJhMiAyIDAgMCAwIDEuNjcuOUgyMGEyIDIgMCAwIDEgMiAydi41IiAvPgogIDxwYXRoIGQ9Ik0xMiAxMHY0aDQiIC8+CiAgPHBhdGggZD0ibTEyIDE0IDEuNTM1LTEuNjA1YTUgNSAwIDAgMSA4IDEuNSIgLz4KICA8cGF0aCBkPSJNMjIgMjJ2LTRoLTQiIC8+CiAgPHBhdGggZD0ibTIyIDE4LTEuNTM1IDEuNjA1YTUgNSAwIDAgMS04LTEuNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/folder-sync
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FolderSync: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M9 20H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v.5',
      },
    ],
    ['path', { d: 'M12 10v4h4' }],
    ['path', { d: 'm12 14 1.535-1.605a5 5 0 0 1 8 1.5' }],
    ['path', { d: 'M22 22v-4h-4' }],
    ['path', { d: 'm22 18-1.535 1.605a5 5 0 0 1-8-1.5' }],
  ],
];

export default FolderSync;
