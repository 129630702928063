import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name database-zap
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8ZWxsaXBzZSBjeD0iMTIiIGN5PSI1IiByeD0iOSIgcnk9IjMiIC8+CiAgPHBhdGggZD0iTTMgNVYxOUE5IDMgMCAwIDAgMTUgMjEuODQiIC8+CiAgPHBhdGggZD0iTTIxIDVWOCIgLz4KICA8cGF0aCBkPSJNMjEgMTJMMTggMTdIMjJMMTkgMjIiIC8+CiAgPHBhdGggZD0iTTMgMTJBOSAzIDAgMCAwIDE0LjU5IDE0Ljg3IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/database-zap
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const DatabaseZap: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['ellipse', { cx: '12', cy: '5', rx: '9', ry: '3' }],
    ['path', { d: 'M3 5V19A9 3 0 0 0 15 21.84' }],
    ['path', { d: 'M21 5V8' }],
    ['path', { d: 'M21 12L18 17H22L19 22' }],
    ['path', { d: 'M3 12A9 3 0 0 0 14.59 14.87' }],
  ],
];

export default DatabaseZap;
