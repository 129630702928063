import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name paintbrush
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTguMzcgMi42MyAxNCA3bC0xLjU5LTEuNTlhMiAyIDAgMCAwLTIuODIgMEw4IDdsOSA5IDEuNTktMS41OWEyIDIgMCAwIDAgMC0yLjgyTDE3IDEwbDQuMzctNC4zN2EyLjEyIDIuMTIgMCAxIDAtMy0zWiIgLz4KICA8cGF0aCBkPSJNOSA4Yy0yIDMtNCAzLjUtNyA0bDggMTBjMi0xIDYtNSA2LTciIC8+CiAgPHBhdGggZD0iTTE0LjUgMTcuNSA0LjUgMTUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/paintbrush
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Paintbrush: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M18.37 2.63 14 7l-1.59-1.59a2 2 0 0 0-2.82 0L8 7l9 9 1.59-1.59a2 2 0 0 0 0-2.82L17 10l4.37-4.37a2.12 2.12 0 1 0-3-3Z',
      },
    ],
    ['path', { d: 'M9 8c-2 3-4 3.5-7 4l8 10c2-1 6-5 6-7' }],
    ['path', { d: 'M14.5 17.5 4.5 15' }],
  ],
];

export default Paintbrush;
