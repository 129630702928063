import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name arrow-up-1-0
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMyA4IDQtNCA0IDQiIC8+CiAgPHBhdGggZD0iTTcgNHYxNiIgLz4KICA8cGF0aCBkPSJNMTcgMTBWNGgtMiIgLz4KICA8cGF0aCBkPSJNMTUgMTBoNCIgLz4KICA8cmVjdCB4PSIxNSIgeT0iMTQiIHdpZHRoPSI0IiBoZWlnaHQ9IjYiIHJ5PSIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/arrow-up-1-0
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ArrowUp10: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm3 8 4-4 4 4' }],
    ['path', { d: 'M7 4v16' }],
    ['path', { d: 'M17 10V4h-2' }],
    ['path', { d: 'M15 10h4' }],
    ['rect', { x: '15', y: '14', width: '4', height: '6', ry: '2' }],
  ],
];

export default ArrowUp10;
