import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bath
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSA2IDYuNSAzLjVhMS41IDEuNSAwIDAgMC0xLS41QzQuNjgzIDMgNCAzLjY4MyA0IDQuNVYxN2EyIDIgMCAwIDAgMiAyaDEyYTIgMiAwIDAgMCAyLTJ2LTUiIC8+CiAgPGxpbmUgeDE9IjEwIiB4Mj0iOCIgeTE9IjUiIHkyPSI3IiAvPgogIDxsaW5lIHgxPSIyIiB4Mj0iMjIiIHkxPSIxMiIgeTI9IjEyIiAvPgogIDxsaW5lIHgxPSI3IiB4Mj0iNyIgeTE9IjE5IiB5Mj0iMjEiIC8+CiAgPGxpbmUgeDE9IjE3IiB4Mj0iMTciIHkxPSIxOSIgeTI9IjIxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/bath
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Bath: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M9 6 6.5 3.5a1.5 1.5 0 0 0-1-.5C4.683 3 4 3.683 4 4.5V17a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-5',
      },
    ],
    ['line', { x1: '10', x2: '8', y1: '5', y2: '7' }],
    ['line', { x1: '2', x2: '22', y1: '12', y2: '12' }],
    ['line', { x1: '7', x2: '7', y1: '19', y2: '21' }],
    ['line', { x1: '17', x2: '17', y1: '19', y2: '21' }],
  ],
];

export default Bath;
