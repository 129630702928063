import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name send-horizontal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMyAzIDMgOS0zIDkgMTktOVoiIC8+CiAgPHBhdGggZD0iTTYgMTJoMTYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/send-horizontal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SendHorizontal: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm3 3 3 9-3 9 19-9Z' }],
    ['path', { d: 'M6 12h16' }],
  ],
];

export default SendHorizontal;
