import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name superscript
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNCAxOSA4LTgiIC8+CiAgPHBhdGggZD0ibTEyIDE5LTgtOCIgLz4KICA8cGF0aCBkPSJNMjAgMTJoLTRjMC0xLjUuNDQyLTIgMS41LTIuNVMyMCA4LjMzNCAyMCA3LjAwMmMwLS40NzItLjE3LS45My0uNDg0LTEuMjlhMi4xMDUgMi4xMDUgMCAwIDAtMi42MTctLjQzNmMtLjQyLjIzOS0uNzM4LjYxNC0uODk5IDEuMDYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/superscript
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Superscript: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm4 19 8-8' }],
    ['path', { d: 'm12 19-8-8' }],
    [
      'path',
      {
        d: 'M20 12h-4c0-1.5.442-2 1.5-2.5S20 8.334 20 7.002c0-.472-.17-.93-.484-1.29a2.105 2.105 0 0 0-2.617-.436c-.42.239-.738.614-.899 1.06',
      },
    ],
  ],
];

export default Superscript;
