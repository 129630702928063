import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name waypoints
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjQuNSIgcj0iMi41IiAvPgogIDxwYXRoIGQ9Im0xMC4yIDYuMy0zLjkgMy45IiAvPgogIDxjaXJjbGUgY3g9IjQuNSIgY3k9IjEyIiByPSIyLjUiIC8+CiAgPHBhdGggZD0iTTcgMTJoMTAiIC8+CiAgPGNpcmNsZSBjeD0iMTkuNSIgY3k9IjEyIiByPSIyLjUiIC8+CiAgPHBhdGggZD0ibTEzLjggMTcuNyAzLjktMy45IiAvPgogIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTkuNSIgcj0iMi41IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/waypoints
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Waypoints: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '4.5', r: '2.5' }],
    ['path', { d: 'm10.2 6.3-3.9 3.9' }],
    ['circle', { cx: '4.5', cy: '12', r: '2.5' }],
    ['path', { d: 'M7 12h10' }],
    ['circle', { cx: '19.5', cy: '12', r: '2.5' }],
    ['path', { d: 'm13.8 17.7 3.9-3.9' }],
    ['circle', { cx: '12', cy: '19.5', r: '2.5' }],
  ],
];

export default Waypoints;
