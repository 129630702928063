import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name flask-conical-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTAgNC43MiAyMC41NWExIDEgMCAwIDAgLjkgMS40NWgxMi43NmExIDEgMCAwIDAgLjktMS40NWwtMS4yNzItMi41NDIiIC8+CiAgPHBhdGggZD0iTTEwIDJ2Mi4zNDMiIC8+CiAgPHBhdGggZD0iTTE0IDJ2Ni4zNDMiIC8+CiAgPHBhdGggZD0iTTguNSAyaDciIC8+CiAgPHBhdGggZD0iTTcgMTZoOSIgLz4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/flask-conical-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FlaskConicalOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 10 4.72 20.55a1 1 0 0 0 .9 1.45h12.76a1 1 0 0 0 .9-1.45l-1.272-2.542' }],
    ['path', { d: 'M10 2v2.343' }],
    ['path', { d: 'M14 2v6.343' }],
    ['path', { d: 'M8.5 2h7' }],
    ['path', { d: 'M7 16h9' }],
    ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
  ],
];

export default FlaskConicalOff;
