import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name notepad-text
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAydjQiIC8+CiAgPHBhdGggZD0iTTEyIDJ2NCIgLz4KICA8cGF0aCBkPSJNMTYgMnY0IiAvPgogIDxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIxOCIgeD0iNCIgeT0iNCIgcng9IjIiIC8+CiAgPHBhdGggZD0iTTggMTBoNiIgLz4KICA8cGF0aCBkPSJNOCAxNGg4IiAvPgogIDxwYXRoIGQ9Ik04IDE4aDUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/notepad-text
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const NotepadText: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M8 2v4' }],
    ['path', { d: 'M12 2v4' }],
    ['path', { d: 'M16 2v4' }],
    ['rect', { width: '16', height: '18', x: '4', y: '4', rx: '2' }],
    ['path', { d: 'M8 10h6' }],
    ['path', { d: 'M8 14h8' }],
    ['path', { d: 'M8 18h5' }],
  ],
];

export default NotepadText;
