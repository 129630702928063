import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name mouse-pointer
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMyAzIDcuMDcgMTYuOTcgMi41MS03LjM5IDcuMzktMi41MUwzIDN6IiAvPgogIDxwYXRoIGQ9Im0xMyAxMyA2IDYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/mouse-pointer
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MousePointer: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm3 3 7.07 16.97 2.51-7.39 7.39-2.51L3 3z' }],
    ['path', { d: 'm13 13 6 6' }],
  ],
];

export default MousePointer;
