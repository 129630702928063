import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name vault
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHg9IjMiIHk9IjMiIHJ4PSIyIiAvPgogIDxjaXJjbGUgY3g9IjcuNSIgY3k9IjcuNSIgcj0iLjUiIGZpbGw9ImN1cnJlbnRDb2xvciIgLz4KICA8cGF0aCBkPSJtNy45IDcuOSAyLjcgMi43IiAvPgogIDxjaXJjbGUgY3g9IjE2LjUiIGN5PSI3LjUiIHI9Ii41IiBmaWxsPSJjdXJyZW50Q29sb3IiIC8+CiAgPHBhdGggZD0ibTEzLjQgMTAuNiAyLjctMi43IiAvPgogIDxjaXJjbGUgY3g9IjcuNSIgY3k9IjE2LjUiIHI9Ii41IiBmaWxsPSJjdXJyZW50Q29sb3IiIC8+CiAgPHBhdGggZD0ibTcuOSAxNi4xIDIuNy0yLjciIC8+CiAgPGNpcmNsZSBjeD0iMTYuNSIgY3k9IjE2LjUiIHI9Ii41IiBmaWxsPSJjdXJyZW50Q29sb3IiIC8+CiAgPHBhdGggZD0ibTEzLjQgMTMuNCAyLjcgMi43IiAvPgogIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/vault
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Vault: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '18', height: '18', x: '3', y: '3', rx: '2' }],
    ['circle', { cx: '7.5', cy: '7.5', r: '.5', fill: 'currentColor' }],
    ['path', { d: 'm7.9 7.9 2.7 2.7' }],
    ['circle', { cx: '16.5', cy: '7.5', r: '.5', fill: 'currentColor' }],
    ['path', { d: 'm13.4 10.6 2.7-2.7' }],
    ['circle', { cx: '7.5', cy: '16.5', r: '.5', fill: 'currentColor' }],
    ['path', { d: 'm7.9 16.1 2.7-2.7' }],
    ['circle', { cx: '16.5', cy: '16.5', r: '.5', fill: 'currentColor' }],
    ['path', { d: 'm13.4 13.4 2.7 2.7' }],
    ['circle', { cx: '12', cy: '12', r: '2' }],
  ],
];

export default Vault;
