import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name combine
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB4PSIyIiB5PSIyIiByeD0iMiIgLz4KICA8cGF0aCBkPSJNMTQgMmMxLjEgMCAyIC45IDIgMnY0YzAgMS4xLS45IDItMiAyIiAvPgogIDxwYXRoIGQ9Ik0yMCAyYzEuMSAwIDIgLjkgMiAydjRjMCAxLjEtLjkgMi0yIDIiIC8+CiAgPHBhdGggZD0iTTEwIDE4SDVjLTEuNyAwLTMtMS4zLTMtM3YtMSIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSI3IDIxIDEwIDE4IDcgMTUiIC8+CiAgPHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iOCIgeD0iMTQiIHk9IjE0IiByeD0iMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/combine
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Combine: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '8', height: '8', x: '2', y: '2', rx: '2' }],
    ['path', { d: 'M14 2c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2' }],
    ['path', { d: 'M20 2c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2' }],
    ['path', { d: 'M10 18H5c-1.7 0-3-1.3-3-3v-1' }],
    ['polyline', { points: '7 21 10 18 7 15' }],
    ['rect', { width: '8', height: '8', x: '14', y: '14', rx: '2' }],
  ],
];

export default Combine;
