import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name parking-meter
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSA5YTMgMyAwIDEgMSA2IDAiIC8+CiAgPHBhdGggZD0iTTEyIDEydjMiIC8+CiAgPHBhdGggZD0iTTExIDE1aDIiIC8+CiAgPHBhdGggZD0iTTE5IDlhNyA3IDAgMSAwLTEzLjYgMi4zQzYuNCAxNC40IDggMTkgOCAxOWg4czEuNi00LjYgMi42LTcuN2MuMy0uOC40LTEuNS40LTIuMyIgLz4KICA8cGF0aCBkPSJNMTIgMTl2MyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/parking-meter
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ParkingMeter: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M9 9a3 3 0 1 1 6 0' }],
    ['path', { d: 'M12 12v3' }],
    ['path', { d: 'M11 15h2' }],
    [
      'path',
      { d: 'M19 9a7 7 0 1 0-13.6 2.3C6.4 14.4 8 19 8 19h8s1.6-4.6 2.6-7.7c.3-.8.4-1.5.4-2.3' },
    ],
    ['path', { d: 'M12 19v3' }],
  ],
];

export default ParkingMeter;
