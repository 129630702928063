import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name venetian-mask
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxMmE1IDUgMCAwIDAgNSA1IDggOCAwIDAgMSA1IDIgOCA4IDAgMCAxIDUtMiA1IDUgMCAwIDAgNS01VjdoLTVhOCA4IDAgMCAwLTUgMiA4IDggMCAwIDAtNS0ySDJaIiAvPgogIDxwYXRoIGQ9Ik02IDExYzEuNSAwIDMgLjUgMyAyLTIgMC0zIDAtMy0yWiIgLz4KICA8cGF0aCBkPSJNMTggMTFjLTEuNSAwLTMgLjUtMyAyIDIgMCAzIDAgMy0yWiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/venetian-mask
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const VenetianMask: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M2 12a5 5 0 0 0 5 5 8 8 0 0 1 5 2 8 8 0 0 1 5-2 5 5 0 0 0 5-5V7h-5a8 8 0 0 0-5 2 8 8 0 0 0-5-2H2Z',
      },
    ],
    ['path', { d: 'M6 11c1.5 0 3 .5 3 2-2 0-3 0-3-2Z' }],
    ['path', { d: 'M18 11c-1.5 0-3 .5-3 2 2 0 3 0 3-2Z' }],
  ],
];

export default VenetianMask;
