import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cctv
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYuNzUgMTJoMy42MzJhMSAxIDAgMCAxIC44OTQgMS40NDdsLTIuMDM0IDQuMDY5YTEgMSAwIDAgMS0xLjcwOC4xMzRsLTIuMTI0LTIuOTciIC8+CiAgPHBhdGggZD0iTTE3LjEwNiA5LjA1M2ExIDEgMCAwIDEgLjQ0NyAxLjM0MWwtMy4xMDYgNi4yMTFhMSAxIDAgMCAxLTEuMzQyLjQ0N0wzLjYxIDEyLjNhMi45MiAyLjkyIDAgMCAxLTEuMy0zLjkxTDMuNjkgNS42YTIuOTIgMi45MiAwIDAgMSAzLjkyLTEuM3oiIC8+CiAgPHBhdGggZD0iTTIgMTloMy43NmEyIDIgMCAwIDAgMS44LTEuMUw5IDE1IiAvPgogIDxwYXRoIGQ9Ik0yIDIxdi00IiAvPgogIDxwYXRoIGQ9Ik03IDloLjAxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/cctv
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Cctv: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M16.75 12h3.632a1 1 0 0 1 .894 1.447l-2.034 4.069a1 1 0 0 1-1.708.134l-2.124-2.97' },
    ],
    [
      'path',
      {
        d: 'M17.106 9.053a1 1 0 0 1 .447 1.341l-3.106 6.211a1 1 0 0 1-1.342.447L3.61 12.3a2.92 2.92 0 0 1-1.3-3.91L3.69 5.6a2.92 2.92 0 0 1 3.92-1.3z',
      },
    ],
    ['path', { d: 'M2 19h3.76a2 2 0 0 0 1.8-1.1L9 15' }],
    ['path', { d: 'M2 21v-4' }],
    ['path', { d: 'M7 9h.01' }],
  ],
];

export default Cctv;
