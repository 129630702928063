import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name sprout
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyAyMGgxMCIgLz4KICA8cGF0aCBkPSJNMTAgMjBjNS41LTIuNS44LTYuNCAzLTEwIiAvPgogIDxwYXRoIGQ9Ik05LjUgOS40YzEuMS44IDEuOCAyLjIgMi4zIDMuNy0yIC40LTMuNS40LTQuOC0uMy0xLjItLjYtMi4zLTEuOS0zLTQuMiAyLjgtLjUgNC40IDAgNS41Ljh6IiAvPgogIDxwYXRoIGQ9Ik0xNC4xIDZhNyA3IDAgMCAwLTEuMSA0YzEuOS0uMSAzLjMtLjYgNC4zLTEuNCAxLTEgMS42LTIuMyAxLjctNC42LTIuNy4xLTQgMS00LjkgMnoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/sprout
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Sprout: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7 20h10' }],
    ['path', { d: 'M10 20c5.5-2.5.8-6.4 3-10' }],
    [
      'path',
      {
        d: 'M9.5 9.4c1.1.8 1.8 2.2 2.3 3.7-2 .4-3.5.4-4.8-.3-1.2-.6-2.3-1.9-3-4.2 2.8-.5 4.4 0 5.5.8z',
      },
    ],
    [
      'path',
      { d: 'M14.1 6a7 7 0 0 0-1.1 4c1.9-.1 3.3-.6 4.3-1.4 1-1 1.6-2.3 1.7-4.6-2.7.1-4 1-4.9 2z' },
    ],
  ],
];

export default Sprout;
