import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name star-half
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTcuOCA1LjggMjEgNyAxNC4xIDIgOS4zbDctMUwxMiAyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/star-half
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const StarHalf: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M12 17.8 5.8 21 7 14.1 2 9.3l7-1L12 2' }]],
];

export default StarHalf;
