import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name align-center-horizontal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxMmgyMCIgLz4KICA8cGF0aCBkPSJNMTAgMTZ2NGEyIDIgMCAwIDEtMiAySDZhMiAyIDAgMCAxLTItMnYtNCIgLz4KICA8cGF0aCBkPSJNMTAgOFY0YTIgMiAwIDAgMC0yLTJINmEyIDIgMCAwIDAtMiAydjQiIC8+CiAgPHBhdGggZD0iTTIwIDE2djFhMiAyIDAgMCAxLTIgMmgtMmEyIDIgMCAwIDEtMi0ydi0xIiAvPgogIDxwYXRoIGQ9Ik0xNCA4VjdjMC0xLjEuOS0yIDItMmgyYTIgMiAwIDAgMSAyIDJ2MSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/align-center-horizontal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const AlignCenterHorizontal: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 12h20' }],
    ['path', { d: 'M10 16v4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-4' }],
    ['path', { d: 'M10 8V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v4' }],
    ['path', { d: 'M20 16v1a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-1' }],
    ['path', { d: 'M14 8V7c0-1.1.9-2 2-2h2a2 2 0 0 1 2 2v1' }],
  ],
];

export default AlignCenterHorizontal;
