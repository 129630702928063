import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bluetooth-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTcgMTctNSA1VjEybC01IDUiIC8+CiAgPHBhdGggZD0ibTIgMiAyMCAyMCIgLz4KICA8cGF0aCBkPSJNMTQuNSA5LjUgMTcgN2wtNS01djQuNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/bluetooth-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BluetoothOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm17 17-5 5V12l-5 5' }],
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M14.5 9.5 17 7l-5-5v4.5' }],
  ],
];

export default BluetoothOff;
