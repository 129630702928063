import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name usb
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMCIgY3k9IjciIHI9IjEiIC8+CiAgPGNpcmNsZSBjeD0iNCIgY3k9IjIwIiByPSIxIiAvPgogIDxwYXRoIGQ9Ik00LjcgMTkuMyAxOSA1IiAvPgogIDxwYXRoIGQ9Im0yMSAzLTMgMSAyIDJaIiAvPgogIDxwYXRoIGQ9Ik05LjI2IDcuNjggNSAxMmwyIDUiIC8+CiAgPHBhdGggZD0ibTEwIDE0IDUgMiAzLjUtMy41IiAvPgogIDxwYXRoIGQ9Im0xOCAxMiAxLTEgMSAxLTEgMVoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/usb
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Usb: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '10', cy: '7', r: '1' }],
    ['circle', { cx: '4', cy: '20', r: '1' }],
    ['path', { d: 'M4.7 19.3 19 5' }],
    ['path', { d: 'm21 3-3 1 2 2Z' }],
    ['path', { d: 'M9.26 7.68 5 12l2 5' }],
    ['path', { d: 'm10 14 5 2 3.5-3.5' }],
    ['path', { d: 'm18 12 1-1 1 1-1 1Z' }],
  ],
];

export default Usb;
