import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name glass-water
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUuMiAyMkg4LjhhMiAyIDAgMCAxLTItMS43OUw1IDNoMTRsLTEuODEgMTcuMjFBMiAyIDAgMCAxIDE1LjIgMjJaIiAvPgogIDxwYXRoIGQ9Ik02IDEyYTUgNSAwIDAgMSA2IDAgNSA1IDAgMCAwIDYgMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/glass-water
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const GlassWater: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M15.2 22H8.8a2 2 0 0 1-2-1.79L5 3h14l-1.81 17.21A2 2 0 0 1 15.2 22Z' }],
    ['path', { d: 'M6 12a5 5 0 0 1 6 0 5 5 0 0 0 6 0' }],
  ],
];

export default GlassWater;
