import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name book-open-text
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAzaDZhNCA0IDAgMCAxIDQgNHYxNGEzIDMgMCAwIDAtMy0zSDJ6IiAvPgogIDxwYXRoIGQ9Ik0yMiAzaC02YTQgNCAwIDAgMC00IDR2MTRhMyAzIDAgMCAxIDMtM2g3eiIgLz4KICA8cGF0aCBkPSJNNiA4aDIiIC8+CiAgPHBhdGggZD0iTTYgMTJoMiIgLz4KICA8cGF0aCBkPSJNMTYgOGgyIiAvPgogIDxwYXRoIGQ9Ik0xNiAxMmgyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/book-open-text
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BookOpenText: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z' }],
    ['path', { d: 'M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z' }],
    ['path', { d: 'M6 8h2' }],
    ['path', { d: 'M6 12h2' }],
    ['path', { d: 'M16 8h2' }],
    ['path', { d: 'M16 12h2' }],
  ],
];

export default BookOpenText;
