import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name square-bottom-dashed-scissors
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAyMmEyIDIgMCAwIDEtMi0yVjRhMiAyIDAgMCAxIDItMmgxNmEyIDIgMCAwIDEgMiAydjE2YTIgMiAwIDAgMS0yIDIiIC8+CiAgPHBhdGggZD0iTTEwIDIySDgiIC8+CiAgPHBhdGggZD0iTTE2IDIyaC0yIiAvPgogIDxjaXJjbGUgY3g9IjgiIGN5PSI4IiByPSIyIiAvPgogIDxwYXRoIGQ9Ik05LjQxNCA5LjQxNCAxMiAxMiIgLz4KICA8cGF0aCBkPSJNMTQuOCAxNC44IDE4IDE4IiAvPgogIDxjaXJjbGUgY3g9IjgiIGN5PSIxNiIgcj0iMiIgLz4KICA8cGF0aCBkPSJtMTggNi04LjU4NiA4LjU4NiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/square-bottom-dashed-scissors
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SquareBottomDashedScissors: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 22a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2' }],
    ['path', { d: 'M10 22H8' }],
    ['path', { d: 'M16 22h-2' }],
    ['circle', { cx: '8', cy: '8', r: '2' }],
    ['path', { d: 'M9.414 9.414 12 12' }],
    ['path', { d: 'M14.8 14.8 18 18' }],
    ['circle', { cx: '8', cy: '16', r: '2' }],
    ['path', { d: 'm18 6-8.586 8.586' }],
  ],
];

export default SquareBottomDashedScissors;
