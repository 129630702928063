import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name drum
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMiAyIDggOCIgLz4KICA8cGF0aCBkPSJtMjIgMi04IDgiIC8+CiAgPGVsbGlwc2UgY3g9IjEyIiBjeT0iOSIgcng9IjEwIiByeT0iNSIgLz4KICA8cGF0aCBkPSJNNyAxMy40djcuOSIgLz4KICA8cGF0aCBkPSJNMTIgMTR2OCIgLz4KICA8cGF0aCBkPSJNMTcgMTMuNHY3LjkiIC8+CiAgPHBhdGggZD0iTTIgOXY4YTEwIDUgMCAwIDAgMjAgMFY5IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/drum
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Drum: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm2 2 8 8' }],
    ['path', { d: 'm22 2-8 8' }],
    ['ellipse', { cx: '12', cy: '9', rx: '10', ry: '5' }],
    ['path', { d: 'M7 13.4v7.9' }],
    ['path', { d: 'M12 14v8' }],
    ['path', { d: 'M17 13.4v7.9' }],
    ['path', { d: 'M2 9v8a10 5 0 0 0 20 0V9' }],
  ],
];

export default Drum;
