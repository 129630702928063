import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bed-single
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyAyMHYtOGEyIDIgMCAwIDEgMi0yaDE0YTIgMiAwIDAgMSAyIDJ2OCIgLz4KICA8cGF0aCBkPSJNNSAxMFY2YTIgMiAwIDAgMSAyLTJoMTBhMiAyIDAgMCAxIDIgMnY0IiAvPgogIDxwYXRoIGQ9Ik0zIDE4aDE4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/bed-single
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BedSingle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 20v-8a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8' }],
    ['path', { d: 'M5 10V6a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v4' }],
    ['path', { d: 'M3 18h18' }],
  ],
];

export default BedSingle;
