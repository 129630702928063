import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name haze
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNS4yIDYuMiAxLjQgMS40IiAvPgogIDxwYXRoIGQ9Ik0yIDEzaDIiIC8+CiAgPHBhdGggZD0iTTIwIDEzaDIiIC8+CiAgPHBhdGggZD0ibTE3LjQgNy42IDEuNC0xLjQiIC8+CiAgPHBhdGggZD0iTTIyIDE3SDIiIC8+CiAgPHBhdGggZD0iTTIyIDIxSDIiIC8+CiAgPHBhdGggZD0iTTE2IDEzYTQgNCAwIDAgMC04IDAiIC8+CiAgPHBhdGggZD0iTTEyIDVWMi41IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/haze
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Haze: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm5.2 6.2 1.4 1.4' }],
    ['path', { d: 'M2 13h2' }],
    ['path', { d: 'M20 13h2' }],
    ['path', { d: 'm17.4 7.6 1.4-1.4' }],
    ['path', { d: 'M22 17H2' }],
    ['path', { d: 'M22 21H2' }],
    ['path', { d: 'M16 13a4 4 0 0 0-8 0' }],
    ['path', { d: 'M12 5V2.5' }],
  ],
];

export default Haze;
