import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name move-horizontal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSIxOCA4IDIyIDEyIDE4IDE2IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjYgOCAyIDEyIDYgMTYiIC8+CiAgPGxpbmUgeDE9IjIiIHgyPSIyMiIgeTE9IjEyIiB5Mj0iMTIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/move-horizontal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MoveHorizontal: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '18 8 22 12 18 16' }],
    ['polyline', { points: '6 8 2 12 6 16' }],
    ['line', { x1: '2', x2: '22', y1: '12', y2: '12' }],
  ],
];

export default MoveHorizontal;
