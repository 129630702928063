import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name message-square-dot
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEuNyAzSDVhMiAyIDAgMCAwLTIgMnYxNmw0LTRoMTJhMiAyIDAgMCAwIDItMnYtMi43IiAvPgogIDxjaXJjbGUgY3g9IjE4IiBjeT0iNiIgcj0iMyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/message-square-dot
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MessageSquareDot: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M11.7 3H5a2 2 0 0 0-2 2v16l4-4h12a2 2 0 0 0 2-2v-2.7' }],
    ['circle', { cx: '18', cy: '6', r: '3' }],
  ],
];

export default MessageSquareDot;
