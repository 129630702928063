import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name heater
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgOGMyLTMtMi0zIDAtNiIgLz4KICA8cGF0aCBkPSJNMTUuNSA4YzItMy0yLTMgMC02IiAvPgogIDxwYXRoIGQ9Ik02IDEwaC4wMSIgLz4KICA8cGF0aCBkPSJNNiAxNGguMDEiIC8+CiAgPHBhdGggZD0iTTEwIDE2di00IiAvPgogIDxwYXRoIGQ9Ik0xNCAxNnYtNCIgLz4KICA8cGF0aCBkPSJNMTggMTZ2LTQiIC8+CiAgPHBhdGggZD0iTTIwIDZhMiAyIDAgMCAxIDIgMnYxMGEyIDIgMCAwIDEtMiAySDRhMiAyIDAgMCAxLTItMlY4YTIgMiAwIDAgMSAyLTJoMyIgLz4KICA8cGF0aCBkPSJNNSAyMHYyIiAvPgogIDxwYXRoIGQ9Ik0xOSAyMHYyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/heater
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Heater: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M11 8c2-3-2-3 0-6' }],
    ['path', { d: 'M15.5 8c2-3-2-3 0-6' }],
    ['path', { d: 'M6 10h.01' }],
    ['path', { d: 'M6 14h.01' }],
    ['path', { d: 'M10 16v-4' }],
    ['path', { d: 'M14 16v-4' }],
    ['path', { d: 'M18 16v-4' }],
    ['path', { d: 'M20 6a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3' }],
    ['path', { d: 'M5 20v2' }],
    ['path', { d: 'M19 20v2' }],
  ],
];

export default Heater;
