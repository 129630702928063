import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name case-sensitive
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMyAxNSA0LTggNCA4IiAvPgogIDxwYXRoIGQ9Ik00IDEzaDYiIC8+CiAgPGNpcmNsZSBjeD0iMTgiIGN5PSIxMiIgcj0iMyIgLz4KICA8cGF0aCBkPSJNMjEgOXY2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/case-sensitive
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CaseSensitive: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm3 15 4-8 4 8' }],
    ['path', { d: 'M4 13h6' }],
    ['circle', { cx: '18', cy: '12', r: '3' }],
    ['path', { d: 'M21 9v6' }],
  ],
];

export default CaseSensitive;
