import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name pentagon
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMy41IDguN2MtLjcuNS0xIDEuNC0uNyAyLjJsMi44IDguN2MuMy44IDEgMS40IDEuOSAxLjRoOS4xYy45IDAgMS42LS42IDEuOS0xLjRsMi44LTguN2MuMy0uOCAwLTEuNy0uNy0yLjJsLTcuNC01LjNhMi4xIDIuMSAwIDAgMC0yLjQgMFoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/pentagon
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Pentagon: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M3.5 8.7c-.7.5-1 1.4-.7 2.2l2.8 8.7c.3.8 1 1.4 1.9 1.4h9.1c.9 0 1.6-.6 1.9-1.4l2.8-8.7c.3-.8 0-1.7-.7-2.2l-7.4-5.3a2.1 2.1 0 0 0-2.4 0Z',
      },
    ],
  ],
];

export default Pentagon;
