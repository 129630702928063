import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name anvil
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyAxMEg2YTQgNCAwIDAgMS00LTQgMSAxIDAgMCAxIDEtMWg0IiAvPgogIDxwYXRoIGQ9Ik03IDVhMSAxIDAgMCAxIDEtMWgxM2ExIDEgMCAwIDEgMSAxIDcgNyAwIDAgMS03IDdIOGExIDEgMCAwIDEtMS0xeiIgLz4KICA8cGF0aCBkPSJNOSAxMnY1IiAvPgogIDxwYXRoIGQ9Ik0xNSAxMnY1IiAvPgogIDxwYXRoIGQ9Ik01IDIwYTMgMyAwIDAgMSAzLTNoOGEzIDMgMCAwIDEgMyAzIDEgMSAwIDAgMS0xIDFINmExIDEgMCAwIDEtMS0xIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/anvil
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Anvil: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7 10H6a4 4 0 0 1-4-4 1 1 0 0 1 1-1h4' }],
    ['path', { d: 'M7 5a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1 7 7 0 0 1-7 7H8a1 1 0 0 1-1-1z' }],
    ['path', { d: 'M9 12v5' }],
    ['path', { d: 'M15 12v5' }],
    ['path', { d: 'M5 20a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3 1 1 0 0 1-1 1H6a1 1 0 0 1-1-1' }],
  ],
];

export default Anvil;
