import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name text-cursor-input
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNSA0aDFhMyAzIDAgMCAxIDMgMyAzIDMgMCAwIDEgMy0zaDEiIC8+CiAgPHBhdGggZD0iTTEzIDIwaC0xYTMgMyAwIDAgMS0zLTMgMyAzIDAgMCAxLTMgM0g1IiAvPgogIDxwYXRoIGQ9Ik01IDE2SDRhMiAyIDAgMCAxLTItMnYtNGEyIDIgMCAwIDEgMi0yaDEiIC8+CiAgPHBhdGggZD0iTTEzIDhoN2EyIDIgMCAwIDEgMiAydjRhMiAyIDAgMCAxLTIgMmgtNyIgLz4KICA8cGF0aCBkPSJNOSA3djEwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/text-cursor-input
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TextCursorInput: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M5 4h1a3 3 0 0 1 3 3 3 3 0 0 1 3-3h1' }],
    ['path', { d: 'M13 20h-1a3 3 0 0 1-3-3 3 3 0 0 1-3 3H5' }],
    ['path', { d: 'M5 16H4a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h1' }],
    ['path', { d: 'M13 8h7a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-7' }],
    ['path', { d: 'M9 7v10' }],
  ],
];

export default TextCursorInput;
