import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name flask-round
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMnY3LjMxIiAvPgogIDxwYXRoIGQ9Ik0xNCA5LjNWMS45OSIgLz4KICA8cGF0aCBkPSJNOC41IDJoNyIgLz4KICA8cGF0aCBkPSJNMTQgOS4zYTYuNSA2LjUgMCAxIDEtNCAwIiAvPgogIDxwYXRoIGQ9Ik01LjUyIDE2aDEyLjk2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/flask-round
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FlaskRound: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 2v7.31' }],
    ['path', { d: 'M14 9.3V1.99' }],
    ['path', { d: 'M8.5 2h7' }],
    ['path', { d: 'M14 9.3a6.5 6.5 0 1 1-4 0' }],
    ['path', { d: 'M5.52 16h12.96' }],
  ],
];

export default FlaskRound;
