import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name pilcrow-left
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgM3YxMSIgLz4KICA8cGF0aCBkPSJNMTQgOWgtM2EzIDMgMCAwIDEgMC02aDkiIC8+CiAgPHBhdGggZD0iTTE4IDN2MTEiIC8+CiAgPHBhdGggZD0iTTIyIDE4SDJsNC00IiAvPgogIDxwYXRoIGQ9Im02IDIyLTQtNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/pilcrow-left
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PilcrowLeft: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 3v11' }],
    ['path', { d: 'M14 9h-3a3 3 0 0 1 0-6h9' }],
    ['path', { d: 'M18 3v11' }],
    ['path', { d: 'M22 18H2l4-4' }],
    ['path', { d: 'm6 22-4-4' }],
  ],
];

export default PilcrowLeft;
