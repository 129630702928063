import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name loader
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMTIiIHgyPSIxMiIgeTE9IjIiIHkyPSI2IiAvPgogIDxsaW5lIHgxPSIxMiIgeDI9IjEyIiB5MT0iMTgiIHkyPSIyMiIgLz4KICA8bGluZSB4MT0iNC45MyIgeDI9IjcuNzYiIHkxPSI0LjkzIiB5Mj0iNy43NiIgLz4KICA8bGluZSB4MT0iMTYuMjQiIHgyPSIxOS4wNyIgeTE9IjE2LjI0IiB5Mj0iMTkuMDciIC8+CiAgPGxpbmUgeDE9IjIiIHgyPSI2IiB5MT0iMTIiIHkyPSIxMiIgLz4KICA8bGluZSB4MT0iMTgiIHgyPSIyMiIgeTE9IjEyIiB5Mj0iMTIiIC8+CiAgPGxpbmUgeDE9IjQuOTMiIHgyPSI3Ljc2IiB5MT0iMTkuMDciIHkyPSIxNi4yNCIgLz4KICA8bGluZSB4MT0iMTYuMjQiIHgyPSIxOS4wNyIgeTE9IjcuNzYiIHkyPSI0LjkzIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/loader
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Loader: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '12', x2: '12', y1: '2', y2: '6' }],
    ['line', { x1: '12', x2: '12', y1: '18', y2: '22' }],
    ['line', { x1: '4.93', x2: '7.76', y1: '4.93', y2: '7.76' }],
    ['line', { x1: '16.24', x2: '19.07', y1: '16.24', y2: '19.07' }],
    ['line', { x1: '2', x2: '6', y1: '12', y2: '12' }],
    ['line', { x1: '18', x2: '22', y1: '12', y2: '12' }],
    ['line', { x1: '4.93', x2: '7.76', y1: '19.07', y2: '16.24' }],
    ['line', { x1: '16.24', x2: '19.07', y1: '7.76', y2: '4.93' }],
  ],
];

export default Loader;
