import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name delete
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjAgNUg5bC03IDcgNyA3aDExYTIgMiAwIDAgMCAyLTJWN2EyIDIgMCAwIDAtMi0yWiIgLz4KICA8bGluZSB4MT0iMTgiIHgyPSIxMiIgeTE9IjkiIHkyPSIxNSIgLz4KICA8bGluZSB4MT0iMTIiIHgyPSIxOCIgeTE9IjkiIHkyPSIxNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/delete
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Delete: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 5H9l-7 7 7 7h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Z' }],
    ['line', { x1: '18', x2: '12', y1: '9', y2: '15' }],
    ['line', { x1: '12', x2: '18', y1: '9', y2: '15' }],
  ],
];

export default Delete;
