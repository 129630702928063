import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bug-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUgNy4xM1Y2YTMgMyAwIDAgMC01LjE0LTIuMUw4IDIiIC8+CiAgPHBhdGggZD0iTTE0LjEyIDMuODggMTYgMiIgLz4KICA8cGF0aCBkPSJNMjIgMTNoLTR2LTJhNCA0IDAgMCAwLTQtNGgtMS4zIiAvPgogIDxwYXRoIGQ9Ik0yMC45NyA1YzAgMi4xLTEuNiAzLjgtMy41IDQiIC8+CiAgPHBhdGggZD0ibTIgMiAyMCAyMCIgLz4KICA8cGF0aCBkPSJNNy43IDcuN0E0IDQgMCAwIDAgNiAxMXYzYTYgNiAwIDAgMCAxMS4xMyAzLjEzIiAvPgogIDxwYXRoIGQ9Ik0xMiAyMHYtOCIgLz4KICA8cGF0aCBkPSJNNiAxM0gyIiAvPgogIDxwYXRoIGQ9Ik0zIDIxYzAtMi4xIDEuNy0zLjkgMy44LTQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/bug-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BugOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M15 7.13V6a3 3 0 0 0-5.14-2.1L8 2' }],
    ['path', { d: 'M14.12 3.88 16 2' }],
    ['path', { d: 'M22 13h-4v-2a4 4 0 0 0-4-4h-1.3' }],
    ['path', { d: 'M20.97 5c0 2.1-1.6 3.8-3.5 4' }],
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M7.7 7.7A4 4 0 0 0 6 11v3a6 6 0 0 0 11.13 3.13' }],
    ['path', { d: 'M12 20v-8' }],
    ['path', { d: 'M6 13H2' }],
    ['path', { d: 'M3 21c0-2.1 1.7-3.9 3.8-4' }],
  ],
];

export default BugOff;
