import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name notebook-tabs
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiA2aDQiIC8+CiAgPHBhdGggZD0iTTIgMTBoNCIgLz4KICA8cGF0aCBkPSJNMiAxNGg0IiAvPgogIDxwYXRoIGQ9Ik0yIDE4aDQiIC8+CiAgPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjIwIiB4PSI0IiB5PSIyIiByeD0iMiIgLz4KICA8cGF0aCBkPSJNMTUgMnYyMCIgLz4KICA8cGF0aCBkPSJNMTUgN2g1IiAvPgogIDxwYXRoIGQ9Ik0xNSAxMmg1IiAvPgogIDxwYXRoIGQ9Ik0xNSAxN2g1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/notebook-tabs
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const NotebookTabs: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 6h4' }],
    ['path', { d: 'M2 10h4' }],
    ['path', { d: 'M2 14h4' }],
    ['path', { d: 'M2 18h4' }],
    ['rect', { width: '16', height: '20', x: '4', y: '2', rx: '2' }],
    ['path', { d: 'M15 2v20' }],
    ['path', { d: 'M15 7h5' }],
    ['path', { d: 'M15 12h5' }],
    ['path', { d: 'M15 17h5' }],
  ],
];

export default NotebookTabs;
