import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name battery-warning
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgN2gyYTIgMiAwIDAgMSAyIDJ2NmMwIDEtMSAyLTIgMmgtMiIgLz4KICA8cGF0aCBkPSJNNiA3SDRhMiAyIDAgMCAwLTIgMnY2YzAgMSAxIDIgMiAyaDIiIC8+CiAgPGxpbmUgeDE9IjIyIiB4Mj0iMjIiIHkxPSIxMSIgeTI9IjEzIiAvPgogIDxsaW5lIHgxPSIxMCIgeDI9IjEwIiB5MT0iNyIgeTI9IjEzIiAvPgogIDxsaW5lIHgxPSIxMCIgeDI9IjEwIiB5MT0iMTciIHkyPSIxNy4wMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/battery-warning
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BatteryWarning: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 7h2a2 2 0 0 1 2 2v6c0 1-1 2-2 2h-2' }],
    ['path', { d: 'M6 7H4a2 2 0 0 0-2 2v6c0 1 1 2 2 2h2' }],
    ['line', { x1: '22', x2: '22', y1: '11', y2: '13' }],
    ['line', { x1: '10', x2: '10', y1: '7', y2: '13' }],
    ['line', { x1: '10', x2: '10', y1: '17', y2: '17.01' }],
  ],
];

export default BatteryWarning;
