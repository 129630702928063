import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name scissors-line-dashed
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNS40MiA5LjQyIDggMTIiIC8+CiAgPGNpcmNsZSBjeD0iNCIgY3k9IjgiIHI9IjIiIC8+CiAgPHBhdGggZD0ibTE0IDYtOC41OCA4LjU4IiAvPgogIDxjaXJjbGUgY3g9IjQiIGN5PSIxNiIgcj0iMiIgLz4KICA8cGF0aCBkPSJNMTAuOCAxNC44IDE0IDE4IiAvPgogIDxwYXRoIGQ9Ik0xNiAxMmgtMiIgLz4KICA8cGF0aCBkPSJNMjIgMTJoLTIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/scissors-line-dashed
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ScissorsLineDashed: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M5.42 9.42 8 12' }],
    ['circle', { cx: '4', cy: '8', r: '2' }],
    ['path', { d: 'm14 6-8.58 8.58' }],
    ['circle', { cx: '4', cy: '16', r: '2' }],
    ['path', { d: 'M10.8 14.8 14 18' }],
    ['path', { d: 'M16 12h-2' }],
    ['path', { d: 'M22 12h-2' }],
  ],
];

export default ScissorsLineDashed;
