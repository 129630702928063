import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name repeat-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMiA5IDMtMyAzIDMiIC8+CiAgPHBhdGggZD0iTTEzIDE4SDdhMiAyIDAgMCAxLTItMlY2IiAvPgogIDxwYXRoIGQ9Im0yMiAxNS0zIDMtMy0zIiAvPgogIDxwYXRoIGQ9Ik0xMSA2aDZhMiAyIDAgMCAxIDIgMnYxMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/repeat-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Repeat2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm2 9 3-3 3 3' }],
    ['path', { d: 'M13 18H7a2 2 0 0 1-2-2V6' }],
    ['path', { d: 'm22 15-3 3-3-3' }],
    ['path', { d: 'M11 6h6a2 2 0 0 1 2 2v10' }],
  ],
];

export default Repeat2;
