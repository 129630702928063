import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name book-dashed
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjAgMjJoLTIiIC8+CiAgPHBhdGggZD0iTTIwIDE1djJoLTIiIC8+CiAgPHBhdGggZD0iTTQgMTkuNVYxNSIgLz4KICA8cGF0aCBkPSJNMjAgOHYzIiAvPgogIDxwYXRoIGQ9Ik0xOCAyaDJ2MiIgLz4KICA8cGF0aCBkPSJNNCAxMVY5IiAvPgogIDxwYXRoIGQ9Ik0xMiAyaDIiIC8+CiAgPHBhdGggZD0iTTEyIDIyaDIiIC8+CiAgPHBhdGggZD0iTTEyIDE3aDIiIC8+CiAgPHBhdGggZD0iTTggMjJINi41YTIuNSAyLjUgMCAwIDEgMC01SDgiIC8+CiAgPHBhdGggZD0iTTQgNXYtLjVBMi41IDIuNSAwIDAgMSA2LjUgMkg4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/book-dashed
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BookDashed: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 22h-2' }],
    ['path', { d: 'M20 15v2h-2' }],
    ['path', { d: 'M4 19.5V15' }],
    ['path', { d: 'M20 8v3' }],
    ['path', { d: 'M18 2h2v2' }],
    ['path', { d: 'M4 11V9' }],
    ['path', { d: 'M12 2h2' }],
    ['path', { d: 'M12 22h2' }],
    ['path', { d: 'M12 17h2' }],
    ['path', { d: 'M8 22H6.5a2.5 2.5 0 0 1 0-5H8' }],
    ['path', { d: 'M4 5v-.5A2.5 2.5 0 0 1 6.5 2H8' }],
  ],
];

export default BookDashed;
