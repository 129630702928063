import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name camera-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgogIDxwYXRoIGQ9Ik03IDdINGEyIDIgMCAwIDAtMiAydjlhMiAyIDAgMCAwIDIgMmgxNiIgLz4KICA8cGF0aCBkPSJNOS41IDRoNUwxNyA3aDNhMiAyIDAgMCAxIDIgMnY3LjUiIC8+CiAgPHBhdGggZD0iTTE0LjEyMSAxNS4xMjFBMyAzIDAgMSAxIDkuODggMTAuODgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/camera-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CameraOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
    ['path', { d: 'M7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16' }],
    ['path', { d: 'M9.5 4h5L17 7h3a2 2 0 0 1 2 2v7.5' }],
    ['path', { d: 'M14.121 15.121A3 3 0 1 1 9.88 10.88' }],
  ],
];

export default CameraOff;
