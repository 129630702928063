import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name pointer-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgNC41VjRhMiAyIDAgMCAwLTIuNDEtMS45NTciIC8+CiAgPHBhdGggZD0iTTEzLjkgOC40YTIgMiAwIDAgMC0xLjI2LTEuMjk1IiAvPgogIDxwYXRoIGQ9Ik0yMS43IDE2LjJBOCA4IDAgMCAwIDIyIDE0di0zYTIgMiAwIDEgMC00IDB2LTFhMiAyIDAgMCAwLTMuNjMtMS4xNTgiIC8+CiAgPHBhdGggZD0ibTcgMTUtMS44LTEuOGEyIDIgMCAwIDAtMi43OSAyLjg2TDYgMTkuN2E3Ljc0IDcuNzQgMCAwIDAgNiAyLjNoMmE4IDggMCAwIDAgNS42NTctMi4zNDMiIC8+CiAgPHBhdGggZD0iTTYgNnY4IiAvPgogIDxwYXRoIGQ9Im0yIDIgMjAgMjAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/pointer-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PointerOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 4.5V4a2 2 0 0 0-2.41-1.957' }],
    ['path', { d: 'M13.9 8.4a2 2 0 0 0-1.26-1.295' }],
    ['path', { d: 'M21.7 16.2A8 8 0 0 0 22 14v-3a2 2 0 1 0-4 0v-1a2 2 0 0 0-3.63-1.158' }],
    [
      'path',
      {
        d: 'm7 15-1.8-1.8a2 2 0 0 0-2.79 2.86L6 19.7a7.74 7.74 0 0 0 6 2.3h2a8 8 0 0 0 5.657-2.343',
      },
    ],
    ['path', { d: 'M6 6v8' }],
    ['path', { d: 'm2 2 20 20' }],
  ],
];

export default PointerOff;
