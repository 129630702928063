import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name plug-zap-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTMgMi0yIDIuNWgzTDEyIDciIC8+CiAgPHBhdGggZD0iTTEwIDE0di0zIiAvPgogIDxwYXRoIGQ9Ik0xNCAxNHYtMyIgLz4KICA8cGF0aCBkPSJNMTEgMTljLTEuNyAwLTMtMS4zLTMtM3YtMmg4djJjMCAxLjctMS4zIDMtMyAzWiIgLz4KICA8cGF0aCBkPSJNMTIgMjJ2LTMiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/plug-zap-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PlugZap2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm13 2-2 2.5h3L12 7' }],
    ['path', { d: 'M10 14v-3' }],
    ['path', { d: 'M14 14v-3' }],
    ['path', { d: 'M11 19c-1.7 0-3-1.3-3-3v-2h8v2c0 1.7-1.3 3-3 3Z' }],
    ['path', { d: 'M12 22v-3' }],
  ],
];

export default PlugZap2;
