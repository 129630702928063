import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name shuffle
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxOGgxLjRjMS4zIDAgMi41LS42IDMuMy0xLjdsNi4xLTguNmMuNy0xLjEgMi0xLjcgMy4zLTEuN0gyMiIgLz4KICA8cGF0aCBkPSJtMTggMiA0IDQtNCA0IiAvPgogIDxwYXRoIGQ9Ik0yIDZoMS45YzEuNSAwIDIuOS45IDMuNiAyLjIiIC8+CiAgPHBhdGggZD0iTTIyIDE4aC01LjljLTEuMyAwLTIuNi0uNy0zLjMtMS44bC0uNS0uOCIgLz4KICA8cGF0aCBkPSJtMTggMTQgNCA0LTQgNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/shuffle
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Shuffle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 18h1.4c1.3 0 2.5-.6 3.3-1.7l6.1-8.6c.7-1.1 2-1.7 3.3-1.7H22' }],
    ['path', { d: 'm18 2 4 4-4 4' }],
    ['path', { d: 'M2 6h1.9c1.5 0 2.9.9 3.6 2.2' }],
    ['path', { d: 'M22 18h-5.9c-1.3 0-2.6-.7-3.3-1.8l-.5-.8' }],
    ['path', { d: 'm18 14 4 4-4 4' }],
  ],
];

export default Shuffle;
