import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name spade
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNSA5Yy0xLjUgMS41LTMgMy4yLTMgNS41QTUuNSA1LjUgMCAwIDAgNy41IDIwYzEuOCAwIDMtLjUgNC41LTIgMS41IDEuNSAyLjcgMiA0LjUgMmE1LjUgNS41IDAgMCAwIDUuNS01LjVjMC0yLjMtMS41LTQtMy01LjVsLTctNy03IDdaIiAvPgogIDxwYXRoIGQ9Ik0xMiAxOHY0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/spade
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Spade: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M5 9c-1.5 1.5-3 3.2-3 5.5A5.5 5.5 0 0 0 7.5 20c1.8 0 3-.5 4.5-2 1.5 1.5 2.7 2 4.5 2a5.5 5.5 0 0 0 5.5-5.5c0-2.3-1.5-4-3-5.5l-7-7-7 7Z',
      },
    ],
    ['path', { d: 'M12 18v4' }],
  ],
];

export default Spade;
