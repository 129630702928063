import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name receipt-pound-sterling
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAydjIwbDItMSAyIDEgMi0xIDIgMSAyLTEgMiAxIDItMSAyIDFWMmwtMiAxLTItMS0yIDEtMi0xLTIgMS0yLTEtMiAxWiIgLz4KICA8cGF0aCBkPSJNOCAxM2g1IiAvPgogIDxwYXRoIGQ9Ik0xMCAxN1Y5LjVhMi41IDIuNSAwIDAgMSA1IDAiIC8+CiAgPHBhdGggZD0iTTggMTdoNyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/receipt-pound-sterling
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ReceiptPoundSterling: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z' }],
    ['path', { d: 'M8 13h5' }],
    ['path', { d: 'M10 17V9.5a2.5 2.5 0 0 1 5 0' }],
    ['path', { d: 'M8 17h7' }],
  ],
];

export default ReceiptPoundSterling;
