import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name move-down-left
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTlINVYxMyIgLz4KICA8cGF0aCBkPSJNMTkgNUw1IDE5IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/move-down-left
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MoveDownLeft: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M11 19H5V13' }],
    ['path', { d: 'M19 5L5 19' }],
  ],
];

export default MoveDownLeft;
