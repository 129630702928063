import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name microwave
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIHg9IjIiIHk9IjQiIHJ4PSIyIiAvPgogIDxyZWN0IHdpZHRoPSI4IiBoZWlnaHQ9IjciIHg9IjYiIHk9IjgiIHJ4PSIxIiAvPgogIDxwYXRoIGQ9Ik0xOCA4djciIC8+CiAgPHBhdGggZD0iTTYgMTl2MiIgLz4KICA8cGF0aCBkPSJNMTggMTl2MiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/microwave
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Microwave: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '20', height: '15', x: '2', y: '4', rx: '2' }],
    ['rect', { width: '8', height: '7', x: '6', y: '8', rx: '1' }],
    ['path', { d: 'M18 8v7' }],
    ['path', { d: 'M6 19v2' }],
    ['path', { d: 'M18 19v2' }],
  ],
];

export default Microwave;
