import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name rat
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcgNWMwLTEuNy0xLjMtMy0zLTNzLTMgMS4zLTMgM2MwIC44LjMgMS41LjggMkgxMWMtMy45IDAtNyAzLjEtNyA3djBjMCAyLjIgMS44IDQgNCA0IiAvPgogIDxwYXRoIGQ9Ik0xNi44IDMuOWMuMy0uMy42LS41IDEtLjcgMS41LS42IDMuMy4xIDMuOSAxLjYuNiAxLjUtLjEgMy4zLTEuNiAzLjlsMS42IDIuOGMuMi4zLjIuNy4yIDEtLjIuOC0uOSAxLjItMS43IDEuMSAwIDAtMS42LS4zLTIuNy0uNkgxN2MtMS43IDAtMyAxLjMtMyAzIiAvPgogIDxwYXRoIGQ9Ik0xMy4yIDE4YTMgMyAwIDAgMC0yLjItNSIgLz4KICA8cGF0aCBkPSJNMTMgMjJINGEyIDIgMCAwIDEgMC00aDEyIiAvPgogIDxwYXRoIGQ9Ik0xNiA5aC4wMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/rat
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Rat: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M17 5c0-1.7-1.3-3-3-3s-3 1.3-3 3c0 .8.3 1.5.8 2H11c-3.9 0-7 3.1-7 7v0c0 2.2 1.8 4 4 4',
      },
    ],
    [
      'path',
      {
        d: 'M16.8 3.9c.3-.3.6-.5 1-.7 1.5-.6 3.3.1 3.9 1.6.6 1.5-.1 3.3-1.6 3.9l1.6 2.8c.2.3.2.7.2 1-.2.8-.9 1.2-1.7 1.1 0 0-1.6-.3-2.7-.6H17c-1.7 0-3 1.3-3 3',
      },
    ],
    ['path', { d: 'M13.2 18a3 3 0 0 0-2.2-5' }],
    ['path', { d: 'M13 22H4a2 2 0 0 1 0-4h12' }],
    ['path', { d: 'M16 9h.01' }],
  ],
];

export default Rat;
