import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name list-ordered
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMTAiIHgyPSIyMSIgeTE9IjYiIHkyPSI2IiAvPgogIDxsaW5lIHgxPSIxMCIgeDI9IjIxIiB5MT0iMTIiIHkyPSIxMiIgLz4KICA8bGluZSB4MT0iMTAiIHgyPSIyMSIgeTE9IjE4IiB5Mj0iMTgiIC8+CiAgPHBhdGggZD0iTTQgNmgxdjQiIC8+CiAgPHBhdGggZD0iTTQgMTBoMiIgLz4KICA8cGF0aCBkPSJNNiAxOEg0YzAtMSAyLTIgMi0zcy0xLTEuNS0yLTEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/list-ordered
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ListOrdered: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '10', x2: '21', y1: '6', y2: '6' }],
    ['line', { x1: '10', x2: '21', y1: '12', y2: '12' }],
    ['line', { x1: '10', x2: '21', y1: '18', y2: '18' }],
    ['path', { d: 'M4 6h1v4' }],
    ['path', { d: 'M4 10h2' }],
    ['path', { d: 'M6 18H4c0-1 2-2 2-3s-1-1.5-2-1' }],
  ],
];

export default ListOrdered;
