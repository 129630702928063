import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name grab
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTggMTEuNVY5YTIgMiAwIDAgMC0yLTJ2MGEyIDIgMCAwIDAtMiAydjEuNCIgLz4KICA8cGF0aCBkPSJNMTQgMTBWOGEyIDIgMCAwIDAtMi0ydjBhMiAyIDAgMCAwLTIgMnYyIiAvPgogIDxwYXRoIGQ9Ik0xMCA5LjlWOWEyIDIgMCAwIDAtMi0ydjBhMiAyIDAgMCAwLTIgMnY1IiAvPgogIDxwYXRoIGQ9Ik02IDE0djBhMiAyIDAgMCAwLTItMnYwYTIgMiAwIDAgMC0yIDJ2MCIgLz4KICA8cGF0aCBkPSJNMTggMTF2MGEyIDIgMCAxIDEgNCAwdjNhOCA4IDAgMCAxLTggOGgtNGE4IDggMCAwIDEtOC04IDIgMiAwIDEgMSA0IDAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/grab
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Grab: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M18 11.5V9a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v1.4' }],
    ['path', { d: 'M14 10V8a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v2' }],
    ['path', { d: 'M10 9.9V9a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v5' }],
    ['path', { d: 'M6 14v0a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v0' }],
    ['path', { d: 'M18 11v0a2 2 0 1 1 4 0v3a8 8 0 0 1-8 8h-4a8 8 0 0 1-8-8 2 2 0 1 1 4 0' }],
  ],
];

export default Grab;
