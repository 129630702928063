import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name mouse-pointer-click
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtOSA5IDUgMTIgMS44LTUuMkwyMSAxNFoiIC8+CiAgPHBhdGggZD0iTTcuMiAyLjIgOCA1LjEiIC8+CiAgPHBhdGggZD0ibTUuMSA4LTIuOS0uOCIgLz4KICA8cGF0aCBkPSJNMTQgNC4xIDEyIDYiIC8+CiAgPHBhdGggZD0ibTYgMTItMS45IDIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/mouse-pointer-click
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MousePointerClick: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm9 9 5 12 1.8-5.2L21 14Z' }],
    ['path', { d: 'M7.2 2.2 8 5.1' }],
    ['path', { d: 'm5.1 8-2.9-.8' }],
    ['path', { d: 'M14 4.1 12 6' }],
    ['path', { d: 'm6 12-1.9 2' }],
  ],
];

export default MousePointerClick;
