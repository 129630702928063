import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name component
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNS41IDguNSA5IDEybC0zLjUgMy41TDIgMTJsMy41LTMuNVoiIC8+CiAgPHBhdGggZD0ibTEyIDIgMy41IDMuNUwxMiA5IDguNSA1LjUgMTIgMloiIC8+CiAgPHBhdGggZD0iTTE4LjUgOC41IDIyIDEybC0zLjUgMy41TDE1IDEybDMuNS0zLjVaIiAvPgogIDxwYXRoIGQ9Im0xMiAxNSAzLjUgMy41TDEyIDIybC0zLjUtMy41TDEyIDE1WiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/component
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Component: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M5.5 8.5 9 12l-3.5 3.5L2 12l3.5-3.5Z' }],
    ['path', { d: 'm12 2 3.5 3.5L12 9 8.5 5.5 12 2Z' }],
    ['path', { d: 'M18.5 8.5 22 12l-3.5 3.5L15 12l3.5-3.5Z' }],
    ['path', { d: 'm12 15 3.5 3.5L12 22l-3.5-3.5L12 15Z' }],
  ],
];

export default Component;
