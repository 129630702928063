import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name rainbow
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjIgMTdhMTAgMTAgMCAwIDAtMjAgMCIgLz4KICA8cGF0aCBkPSJNNiAxN2E2IDYgMCAwIDEgMTIgMCIgLz4KICA8cGF0aCBkPSJNMTAgMTdhMiAyIDAgMCAxIDQgMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/rainbow
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Rainbow: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M22 17a10 10 0 0 0-20 0' }],
    ['path', { d: 'M6 17a6 6 0 0 1 12 0' }],
    ['path', { d: 'M10 17a2 2 0 0 1 4 0' }],
  ],
];

export default Rainbow;
