import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name lamp-desk
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTQgNS0zIDMgMiA3IDgtOC03LTJaIiAvPgogIDxwYXRoIGQ9Im0xNCA1LTMgMy0zLTMgMy0zIDMgM1oiIC8+CiAgPHBhdGggZD0iTTkuNSA2LjUgNCAxMmwzIDYiIC8+CiAgPHBhdGggZD0iTTMgMjJ2LTJjMC0xLjEuOS0yIDItMmg0YTIgMiAwIDAgMSAyIDJ2MkgzWiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/lamp-desk
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const LampDesk: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm14 5-3 3 2 7 8-8-7-2Z' }],
    ['path', { d: 'm14 5-3 3-3-3 3-3 3 3Z' }],
    ['path', { d: 'M9.5 6.5 4 12l3 6' }],
    ['path', { d: 'M3 22v-2c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v2H3Z' }],
  ],
];

export default LampDesk;
