import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name separator-vertical
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMTIiIHgyPSIxMiIgeTE9IjMiIHkyPSIyMSIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSI4IDggNCAxMiA4IDE2IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjE2IDE2IDIwIDEyIDE2IDgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/separator-vertical
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SeparatorVertical: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '12', x2: '12', y1: '3', y2: '21' }],
    ['polyline', { points: '8 8 4 12 8 16' }],
    ['polyline', { points: '16 16 20 12 16 8' }],
  ],
];

export default SeparatorVertical;
