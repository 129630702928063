import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tree-pine
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTcgMTQgMyAzLjNhMSAxIDAgMCAxLS43IDEuN0g0LjdhMSAxIDAgMCAxLS43LTEuN0w3IDE0aC0uM2ExIDEgMCAwIDEtLjctMS43TDkgOWgtLjJBMSAxIDAgMCAxIDggNy4zTDEyIDNsNCA0LjNhMSAxIDAgMCAxLS44IDEuN0gxNWwzIDMuM2ExIDEgMCAwIDEtLjcgMS43SDE3WiIgLz4KICA8cGF0aCBkPSJNMTIgMjJ2LTMiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/tree-pine
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TreePine: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm17 14 3 3.3a1 1 0 0 1-.7 1.7H4.7a1 1 0 0 1-.7-1.7L7 14h-.3a1 1 0 0 1-.7-1.7L9 9h-.2A1 1 0 0 1 8 7.3L12 3l4 4.3a1 1 0 0 1-.8 1.7H15l3 3.3a1 1 0 0 1-.7 1.7H17Z',
      },
    ],
    ['path', { d: 'M12 22v-3' }],
  ],
];

export default TreePine;
