import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name citrus
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEuNjYgMTcuNjdhMS4wOCAxLjA4IDAgMCAxLS4wNCAxLjZBMTIgMTIgMCAwIDEgNC43MyAyLjM4YTEuMSAxLjEgMCAwIDEgMS42MS0uMDR6IiAvPgogIDxwYXRoIGQ9Ik0xOS42NSAxNS42NkE4IDggMCAwIDEgOC4zNSA0LjM0IiAvPgogIDxwYXRoIGQ9Im0xNCAxMC01LjUgNS41IiAvPgogIDxwYXRoIGQ9Ik0xNCAxNy44NVYxMEg2LjE1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/citrus
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Citrus: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M21.66 17.67a1.08 1.08 0 0 1-.04 1.6A12 12 0 0 1 4.73 2.38a1.1 1.1 0 0 1 1.61-.04z' },
    ],
    ['path', { d: 'M19.65 15.66A8 8 0 0 1 8.35 4.34' }],
    ['path', { d: 'm14 10-5.5 5.5' }],
    ['path', { d: 'M14 17.85V10H6.15' }],
  ],
];

export default Citrus;
