import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name text-quote
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcgNkgzIiAvPgogIDxwYXRoIGQ9Ik0yMSAxMkg4IiAvPgogIDxwYXRoIGQ9Ik0yMSAxOEg4IiAvPgogIDxwYXRoIGQ9Ik0zIDEydjYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/text-quote
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TextQuote: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M17 6H3' }],
    ['path', { d: 'M21 12H8' }],
    ['path', { d: 'M21 18H8' }],
    ['path', { d: 'M3 12v6' }],
  ],
];

export default TextQuote;
