import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name step-back
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMTgiIHgyPSIxOCIgeTE9IjIwIiB5Mj0iNCIgLz4KICA8cG9seWdvbiBwb2ludHM9IjE0LDIwIDQsMTIgMTQsNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/step-back
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const StepBack: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '18', x2: '18', y1: '20', y2: '4' }],
    ['polygon', { points: '14,20 4,12 14,4' }],
  ],
];

export default StepBack;
