import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name paintbrush-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgMTkuOVYxNmgzYTIgMiAwIDAgMCAyLTJ2LTJINXYyYzAgMS4xLjkgMiAyIDJoM3YzLjlhMiAyIDAgMSAwIDQgMFoiIC8+CiAgPHBhdGggZD0iTTYgMTJWMmgxMnYxMCIgLz4KICA8cGF0aCBkPSJNMTQgMnY0IiAvPgogIDxwYXRoIGQ9Ik0xMCAydjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/paintbrush-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Paintbrush2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 19.9V16h3a2 2 0 0 0 2-2v-2H5v2c0 1.1.9 2 2 2h3v3.9a2 2 0 1 0 4 0Z' }],
    ['path', { d: 'M6 12V2h12v10' }],
    ['path', { d: 'M14 2v4' }],
    ['path', { d: 'M10 2v2' }],
  ],
];

export default Paintbrush2;
