import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name move-vertical
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSI4IDE4IDEyIDIyIDE2IDE4IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjggNiAxMiAyIDE2IDYiIC8+CiAgPGxpbmUgeDE9IjEyIiB4Mj0iMTIiIHkxPSIyIiB5Mj0iMjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/move-vertical
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MoveVertical: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '8 18 12 22 16 18' }],
    ['polyline', { points: '8 6 12 2 16 6' }],
    ['line', { x1: '12', x2: '12', y1: '2', y2: '22' }],
  ],
];

export default MoveVertical;
