import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name person-standing
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjUiIHI9IjEiIC8+CiAgPHBhdGggZD0ibTkgMjAgMy02IDMgNiIgLz4KICA8cGF0aCBkPSJtNiA4IDYgMiA2LTIiIC8+CiAgPHBhdGggZD0iTTEyIDEwdjQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/person-standing
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PersonStanding: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '5', r: '1' }],
    ['path', { d: 'm9 20 3-6 3 6' }],
    ['path', { d: 'm6 8 6 2 6-2' }],
    ['path', { d: 'M12 10v4' }],
  ],
];

export default PersonStanding;
