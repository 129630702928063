import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name flashlight-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYgMTZ2NGEyIDIgMCAwIDEtMiAyaC00YTIgMiAwIDAgMS0yLTJWMTBjMC0yLTItMi0yLTQiIC8+CiAgPHBhdGggZD0iTTcgMmgxMXY0YzAgMi0yIDItMiA0djEiIC8+CiAgPGxpbmUgeDE9IjExIiB4Mj0iMTgiIHkxPSI2IiB5Mj0iNiIgLz4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/flashlight-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FlashlightOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M16 16v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V10c0-2-2-2-2-4' }],
    ['path', { d: 'M7 2h11v4c0 2-2 2-2 4v1' }],
    ['line', { x1: '11', x2: '18', y1: '6', y2: '6' }],
    ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
  ],
];

export default FlashlightOff;
