import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name message-square-dashed
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyA2VjVjMC0xLjEuOS0yIDItMmgyIiAvPgogIDxwYXRoIGQ9Ik0xMSAzaDMiIC8+CiAgPHBhdGggZD0iTTE4IDNoMWMxLjEgMCAyIC45IDIgMiIgLz4KICA8cGF0aCBkPSJNMjEgOXYyIiAvPgogIDxwYXRoIGQ9Ik0yMSAxNWMwIDEuMS0uOSAyLTIgMmgtMSIgLz4KICA8cGF0aCBkPSJNMTQgMTdoLTMiIC8+CiAgPHBhdGggZD0ibTcgMTctNCA0di01IiAvPgogIDxwYXRoIGQ9Ik0zIDEydi0yIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/message-square-dashed
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MessageSquareDashed: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 6V5c0-1.1.9-2 2-2h2' }],
    ['path', { d: 'M11 3h3' }],
    ['path', { d: 'M18 3h1c1.1 0 2 .9 2 2' }],
    ['path', { d: 'M21 9v2' }],
    ['path', { d: 'M21 15c0 1.1-.9 2-2 2h-1' }],
    ['path', { d: 'M14 17h-3' }],
    ['path', { d: 'm7 17-4 4v-5' }],
    ['path', { d: 'M3 12v-2' }],
  ],
];

export default MessageSquareDashed;
