import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name remove-formatting
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCA3VjRoMTZ2MyIgLz4KICA8cGF0aCBkPSJNNSAyMGg2IiAvPgogIDxwYXRoIGQ9Ik0xMyA0IDggMjAiIC8+CiAgPHBhdGggZD0ibTE1IDE1IDUgNSIgLz4KICA8cGF0aCBkPSJtMjAgMTUtNSA1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/remove-formatting
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const RemoveFormatting: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 7V4h16v3' }],
    ['path', { d: 'M5 20h6' }],
    ['path', { d: 'M13 4 8 20' }],
    ['path', { d: 'm15 15 5 5' }],
    ['path', { d: 'm20 15-5 5' }],
  ],
];

export default RemoveFormatting;
