import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name lightbulb-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYuOCAxMS4yYy44LS45IDEuMi0yIDEuMi0zLjJhNiA2IDAgMCAwLTkuMy01IiAvPgogIDxwYXRoIGQ9Im0yIDIgMjAgMjAiIC8+CiAgPHBhdGggZD0iTTYuMyA2LjNhNC42NyA0LjY3IDAgMCAwIDEuMiA1LjJjLjcuNyAxLjMgMS41IDEuNSAyLjUiIC8+CiAgPHBhdGggZD0iTTkgMThoNiIgLz4KICA8cGF0aCBkPSJNMTAgMjJoNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/lightbulb-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const LightbulbOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M16.8 11.2c.8-.9 1.2-2 1.2-3.2a6 6 0 0 0-9.3-5' }],
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M6.3 6.3a4.67 4.67 0 0 0 1.2 5.2c.7.7 1.3 1.5 1.5 2.5' }],
    ['path', { d: 'M9 18h6' }],
    ['path', { d: 'M10 22h4' }],
  ],
];

export default LightbulbOff;
