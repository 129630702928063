import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name milk
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAyaDgiIC8+CiAgPHBhdGggZD0iTTkgMnYyLjc4OWE0IDQgMCAwIDEtLjY3MiAyLjIxOWwtLjY1Ni45ODRBNCA0IDAgMCAwIDcgMTAuMjEyVjIwYTIgMiAwIDAgMCAyIDJoNmEyIDIgMCAwIDAgMi0ydi05Ljc4OWE0IDQgMCAwIDAtLjY3Mi0yLjIxOWwtLjY1Ni0uOTg0QTQgNCAwIDAgMSAxNSA0Ljc4OFYyIiAvPgogIDxwYXRoIGQ9Ik03IDE1YTYuNDcyIDYuNDcyIDAgMCAxIDUgMCA2LjQ3IDYuNDcgMCAwIDAgNSAwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/milk
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Milk: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M8 2h8' }],
    [
      'path',
      {
        d: 'M9 2v2.789a4 4 0 0 1-.672 2.219l-.656.984A4 4 0 0 0 7 10.212V20a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-9.789a4 4 0 0 0-.672-2.219l-.656-.984A4 4 0 0 1 15 4.788V2',
      },
    ],
    ['path', { d: 'M7 15a6.472 6.472 0 0 1 5 0 6.47 6.47 0 0 0 5 0' }],
  ],
];

export default Milk;
