import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bar-chart-big
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyAzdjE4aDE4IiAvPgogIDxyZWN0IHdpZHRoPSI0IiBoZWlnaHQ9IjciIHg9IjciIHk9IjEwIiByeD0iMSIgLz4KICA8cmVjdCB3aWR0aD0iNCIgaGVpZ2h0PSIxMiIgeD0iMTUiIHk9IjUiIHJ4PSIxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/bar-chart-big
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BarChartBig: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 3v18h18' }],
    ['rect', { width: '4', height: '7', x: '7', y: '10', rx: '1' }],
    ['rect', { width: '4', height: '12', x: '15', y: '5', rx: '1' }],
  ],
];

export default BarChartBig;
