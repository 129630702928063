import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name piano
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTguNSA4Yy0xLjQgMC0yLjYtLjgtMy4yLTJBNi44NyA2Ljg3IDAgMCAwIDIgOXYxMWEyIDIgMCAwIDAgMiAyaDE2YTIgMiAwIDAgMCAyLTJ2LTguNUMyMiA5LjYgMjAuNCA4IDE4LjUgOCIgLz4KICA8cGF0aCBkPSJNMiAxNGgyMCIgLz4KICA8cGF0aCBkPSJNNiAxNHY0IiAvPgogIDxwYXRoIGQ9Ik0xMCAxNHY0IiAvPgogIDxwYXRoIGQ9Ik0xNCAxNHY0IiAvPgogIDxwYXRoIGQ9Ik0xOCAxNHY0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/piano
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Piano: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M18.5 8c-1.4 0-2.6-.8-3.2-2A6.87 6.87 0 0 0 2 9v11a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-8.5C22 9.6 20.4 8 18.5 8',
      },
    ],
    ['path', { d: 'M2 14h20' }],
    ['path', { d: 'M6 14v4' }],
    ['path', { d: 'M10 14v4' }],
    ['path', { d: 'M14 14v4' }],
    ['path', { d: 'M18 14v4' }],
  ],
];

export default Piano;
