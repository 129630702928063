import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cloud-cog
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjE3IiByPSIzIiAvPgogIDxwYXRoIGQ9Ik00LjIgMTUuMUE3IDcgMCAxIDEgMTUuNzEgOGgxLjc5YTQuNSA0LjUgMCAwIDEgMi41IDguMiIgLz4KICA8cGF0aCBkPSJtMTUuNyAxOC40LS45LS4zIiAvPgogIDxwYXRoIGQ9Im05LjIgMTUuOS0uOS0uMyIgLz4KICA8cGF0aCBkPSJtMTAuNiAyMC43LjMtLjkiIC8+CiAgPHBhdGggZD0ibTEzLjEgMTQuMi4zLS45IiAvPgogIDxwYXRoIGQ9Im0xMy42IDIwLjctLjQtMSIgLz4KICA8cGF0aCBkPSJtMTAuOCAxNC4zLS40LTEiIC8+CiAgPHBhdGggZD0ibTguMyAxOC42IDEtLjQiIC8+CiAgPHBhdGggZD0ibTE0LjcgMTUuOCAxLS40IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/cloud-cog
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CloudCog: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '17', r: '3' }],
    ['path', { d: 'M4.2 15.1A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.2' }],
    ['path', { d: 'm15.7 18.4-.9-.3' }],
    ['path', { d: 'm9.2 15.9-.9-.3' }],
    ['path', { d: 'm10.6 20.7.3-.9' }],
    ['path', { d: 'm13.1 14.2.3-.9' }],
    ['path', { d: 'm13.6 20.7-.4-1' }],
    ['path', { d: 'm10.8 14.3-.4-1' }],
    ['path', { d: 'm8.3 18.6 1-.4' }],
    ['path', { d: 'm14.7 15.8 1-.4' }],
  ],
];

export default CloudCog;
