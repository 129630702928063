import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name train-front-tunnel
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAyMlYxMmExMCAxMCAwIDEgMSAyMCAwdjEwIiAvPgogIDxwYXRoIGQ9Ik0xNSA2Ljh2MS40YTMgMi44IDAgMSAxLTYgMFY2LjgiIC8+CiAgPHBhdGggZD0iTTEwIDE1aC4wMSIgLz4KICA8cGF0aCBkPSJNMTQgMTVoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xMCAxOWE0IDQgMCAwIDEtNC00di0zYTYgNiAwIDEgMSAxMiAwdjNhNCA0IDAgMCAxLTQgNFoiIC8+CiAgPHBhdGggZD0ibTkgMTktMiAzIiAvPgogIDxwYXRoIGQ9Im0xNSAxOSAyIDMiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/train-front-tunnel
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TrainFrontTunnel: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 22V12a10 10 0 1 1 20 0v10' }],
    ['path', { d: 'M15 6.8v1.4a3 2.8 0 1 1-6 0V6.8' }],
    ['path', { d: 'M10 15h.01' }],
    ['path', { d: 'M14 15h.01' }],
    ['path', { d: 'M10 19a4 4 0 0 1-4-4v-3a6 6 0 1 1 12 0v3a4 4 0 0 1-4 4Z' }],
    ['path', { d: 'm9 19-2 3' }],
    ['path', { d: 'm15 19 2 3' }],
  ],
];

export default TrainFrontTunnel;
