import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name audio-waveform
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxM2EyIDIgMCAwIDAgMi0yVjdhMiAyIDAgMCAxIDQgMHYxM2EyIDIgMCAwIDAgNCAwVjRhMiAyIDAgMCAxIDQgMHYxM2EyIDIgMCAwIDAgNCAwdi00YTIgMiAwIDAgMSAyLTIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/audio-waveform
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const AudioWaveform: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M2 13a2 2 0 0 0 2-2V7a2 2 0 0 1 4 0v13a2 2 0 0 0 4 0V4a2 2 0 0 1 4 0v13a2 2 0 0 0 4 0v-4a2 2 0 0 1 2-2',
      },
    ],
  ],
];

export default AudioWaveform;
