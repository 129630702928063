import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bar-chart-horizontal-big
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyAzdjE4aDE4IiAvPgogIDxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSI0IiB4PSI3IiB5PSI1IiByeD0iMSIgLz4KICA8cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB4PSI3IiB5PSIxMyIgcng9IjEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/bar-chart-horizontal-big
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BarChartHorizontalBig: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 3v18h18' }],
    ['rect', { width: '12', height: '4', x: '7', y: '5', rx: '1' }],
    ['rect', { width: '7', height: '4', x: '7', y: '13', rx: '1' }],
  ],
];

export default BarChartHorizontalBig;
