import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name megaphone-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOS4yNiA5LjI2IDMgMTF2M2wxNC4xNCAzLjE0IiAvPgogIDxwYXRoIGQ9Ik0yMSAxNS4zNFY2bC03LjMxIDIuMDMiIC8+CiAgPHBhdGggZD0iTTExLjYgMTYuOGEzIDMgMCAxIDEtNS44LTEuNiIgLz4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/megaphone-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MegaphoneOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M9.26 9.26 3 11v3l14.14 3.14' }],
    ['path', { d: 'M21 15.34V6l-7.31 2.03' }],
    ['path', { d: 'M11.6 16.8a3 3 0 1 1-5.8-1.6' }],
    ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
  ],
];

export default MegaphoneOff;
