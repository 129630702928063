import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name touchpad-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCA0YTIgMiAwIDAgMC0yIDJ2MTJhMiAyIDAgMCAwIDIgMmgxNiIgLz4KICA8cGF0aCBkPSJNMiAxNGgxMiIgLz4KICA8cGF0aCBkPSJNMjIgMTRoLTIiIC8+CiAgPHBhdGggZD0iTTEyIDIwdi02IiAvPgogIDxwYXRoIGQ9Im0yIDIgMjAgMjAiIC8+CiAgPHBhdGggZD0iTTIyIDE2VjZhMiAyIDAgMCAwLTItMkgxMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/touchpad-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TouchpadOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16' }],
    ['path', { d: 'M2 14h12' }],
    ['path', { d: 'M22 14h-2' }],
    ['path', { d: 'M12 20v-6' }],
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M22 16V6a2 2 0 0 0-2-2H10' }],
  ],
];

export default TouchpadOff;
