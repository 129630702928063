import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name school
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgMjJ2LTRhMiAyIDAgMSAwLTQgMHY0IiAvPgogIDxwYXRoIGQ9Im0xOCAxMCA0IDJ2OGEyIDIgMCAwIDEtMiAySDRhMiAyIDAgMCAxLTItMnYtOGw0LTIiIC8+CiAgPHBhdGggZD0iTTE4IDV2MTciIC8+CiAgPHBhdGggZD0ibTQgNiA4LTQgOCA0IiAvPgogIDxwYXRoIGQ9Ik02IDV2MTciIC8+CiAgPGNpcmNsZSBjeD0iMTIiIGN5PSI5IiByPSIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/school
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const School: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 22v-4a2 2 0 1 0-4 0v4' }],
    ['path', { d: 'm18 10 4 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8l4-2' }],
    ['path', { d: 'M18 5v17' }],
    ['path', { d: 'm4 6 8-4 8 4' }],
    ['path', { d: 'M6 5v17' }],
    ['circle', { cx: '12', cy: '9', r: '2' }],
  ],
];

export default School;
