import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name file-music
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxNCIgY3k9IjE2IiByPSIyIiAvPgogIDxjaXJjbGUgY3g9IjYiIGN5PSIxOCIgcj0iMiIgLz4KICA8cGF0aCBkPSJNNCAxMi40VjRhMiAyIDAgMCAxIDItMmg4LjVMMjAgNy41VjIwYTIgMiAwIDAgMS0yIDJoLTcuNSIgLz4KICA8cGF0aCBkPSJNOCAxOHYtNy43TDE2IDl2NyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/file-music
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FileMusic: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '14', cy: '16', r: '2' }],
    ['circle', { cx: '6', cy: '18', r: '2' }],
    ['path', { d: 'M4 12.4V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2h-7.5' }],
    ['path', { d: 'M8 18v-7.7L16 9v7' }],
  ],
];

export default FileMusic;
