import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cloud-moon-rain
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAuMDgzIDlBNi4wMDIgNi4wMDIgMCAwIDEgMTYgNGE0LjI0MyA0LjI0MyAwIDAgMCA2IDZjMCAyLjIyLTEuMjA2IDQuMTYtMyA1LjE5NyIgLz4KICA8cGF0aCBkPSJNMyAyMGE1IDUgMCAxIDEgOC45LTRIMTNhMyAzIDAgMCAxIDIgNS4yNCIgLz4KICA8cGF0aCBkPSJNMTEgMjB2MiIgLz4KICA8cGF0aCBkPSJNNyAxOXYyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/cloud-moon-rain
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CloudMoonRain: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M10.083 9A6.002 6.002 0 0 1 16 4a4.243 4.243 0 0 0 6 6c0 2.22-1.206 4.16-3 5.197' },
    ],
    ['path', { d: 'M3 20a5 5 0 1 1 8.9-4H13a3 3 0 0 1 2 5.24' }],
    ['path', { d: 'M11 20v2' }],
    ['path', { d: 'M7 19v2' }],
  ],
];

export default CloudMoonRain;
