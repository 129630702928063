import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name radiation
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTJoMC4wMSIgLz4KICA8cGF0aCBkPSJNNy41IDQuMmMtLjMtLjUtLjktLjctMS4zLS40QzMuOSA1LjUgMi4zIDguMSAyIDExYy0uMS41LjQgMSAxIDFoNWMwLTEuNS44LTIuOCAyLTMuNC0xLjEtMS45LTItMy41LTIuNS00LjR6IiAvPgogIDxwYXRoIGQ9Ik0yMSAxMmMuNiAwIDEtLjQgMS0xLS4zLTIuOS0xLjgtNS41LTQuMS03LjEtLjQtLjMtMS4xLS4yLTEuMy4zLS42LjktMS41IDIuNS0yLjYgNC4zIDEuMi43IDIgMiAyIDMuNWg1eiIgLz4KICA8cGF0aCBkPSJNNy41IDE5LjhjLS4zLjUtLjEgMS4xLjQgMS4zIDIuNiAxLjIgNS42IDEuMiA4LjIgMCAuNS0uMi43LS44LjQtMS4zLS41LS45LTEuNC0yLjUtMi41LTQuMy0xLjIuNy0yLjguNy00IDAtMS4xIDEuOC0yIDMuNC0yLjUgNC4zeiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/radiation
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Radiation: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 12h0.01' }],
    [
      'path',
      {
        d: 'M7.5 4.2c-.3-.5-.9-.7-1.3-.4C3.9 5.5 2.3 8.1 2 11c-.1.5.4 1 1 1h5c0-1.5.8-2.8 2-3.4-1.1-1.9-2-3.5-2.5-4.4z',
      },
    ],
    [
      'path',
      {
        d: 'M21 12c.6 0 1-.4 1-1-.3-2.9-1.8-5.5-4.1-7.1-.4-.3-1.1-.2-1.3.3-.6.9-1.5 2.5-2.6 4.3 1.2.7 2 2 2 3.5h5z',
      },
    ],
    [
      'path',
      {
        d: 'M7.5 19.8c-.3.5-.1 1.1.4 1.3 2.6 1.2 5.6 1.2 8.2 0 .5-.2.7-.8.4-1.3-.5-.9-1.4-2.5-2.5-4.3-1.2.7-2.8.7-4 0-1.1 1.8-2 3.4-2.5 4.3z',
      },
    ],
  ],
];

export default Radiation;
