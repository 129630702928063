import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name mouse-pointer-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNCA0IDcuMDcgMTcgMi41MS03LjM5TDIxIDExLjA3eiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/mouse-pointer-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MousePointer2: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'm4 4 7.07 17 2.51-7.39L21 11.07z' }]],
];

export default MousePointer2;
