import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name octagon-pause
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTVWOSIgLz4KICA8cGF0aCBkPSJNMTQgMTVWOSIgLz4KICA8cGF0aCBkPSJNNy43MTQgMmg4LjU3MkwyMiA3LjcxNHY4LjU3MkwxNi4yODYgMjJINy43MTRMMiAxNi4yODZWNy43MTR6IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/octagon-pause
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const OctagonPause: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 15V9' }],
    ['path', { d: 'M14 15V9' }],
    ['path', { d: 'M7.714 2h8.572L22 7.714v8.572L16.286 22H7.714L2 16.286V7.714z' }],
  ],
];

export default OctagonPause;
