import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name arrow-down-wide-narrow
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMyAxNiA0IDQgNC00IiAvPgogIDxwYXRoIGQ9Ik03IDIwVjQiIC8+CiAgPHBhdGggZD0iTTExIDRoMTAiIC8+CiAgPHBhdGggZD0iTTExIDhoNyIgLz4KICA8cGF0aCBkPSJNMTEgMTJoNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/arrow-down-wide-narrow
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ArrowDownWideNarrow: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm3 16 4 4 4-4' }],
    ['path', { d: 'M7 20V4' }],
    ['path', { d: 'M11 4h10' }],
    ['path', { d: 'M11 8h7' }],
    ['path', { d: 'M11 12h4' }],
  ],
];

export default ArrowDownWideNarrow;
