import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name corner-right-down
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSIxMCAxNSAxNSAyMCAyMCAxNSIgLz4KICA8cGF0aCBkPSJNNCA0aDdhNCA0IDAgMCAxIDQgNHYxMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/corner-right-down
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CornerRightDown: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '10 15 15 20 20 15' }],
    ['path', { d: 'M4 4h7a4 4 0 0 1 4 4v12' }],
  ],
];

export default CornerRightDown;
