import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name message-circle-more
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNy45IDIwQTkgOSAwIDEgMCA0IDE2LjFMMiAyMloiIC8+CiAgPHBhdGggZD0iTTggMTJoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xMiAxMmguMDEiIC8+CiAgPHBhdGggZD0iTTE2IDEyaC4wMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/message-circle-more
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MessageCircleMore: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7.9 20A9 9 0 1 0 4 16.1L2 22Z' }],
    ['path', { d: 'M8 12h.01' }],
    ['path', { d: 'M12 12h.01' }],
    ['path', { d: 'M16 12h.01' }],
  ],
];

export default MessageCircleMore;
