import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name separator-horizontal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMyIgeDI9IjIxIiB5MT0iMTIiIHkyPSIxMiIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSI4IDggMTIgNCAxNiA4IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjE2IDE2IDEyIDIwIDggMTYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/separator-horizontal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SeparatorHorizontal: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '3', x2: '21', y1: '12', y2: '12' }],
    ['polyline', { points: '8 8 12 4 16 8' }],
    ['polyline', { points: '16 16 12 20 8 16' }],
  ],
];

export default SeparatorHorizontal;
