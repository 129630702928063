import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name contact
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcgMThhMiAyIDAgMCAwLTItMkg5YTIgMiAwIDAgMC0yIDIiIC8+CiAgPHJlY3Qgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiB4PSIzIiB5PSI0IiByeD0iMiIgLz4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEwIiByPSIyIiAvPgogIDxsaW5lIHgxPSI4IiB4Mj0iOCIgeTE9IjIiIHkyPSI0IiAvPgogIDxsaW5lIHgxPSIxNiIgeDI9IjE2IiB5MT0iMiIgeTI9IjQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/contact
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Contact: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M17 18a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2' }],
    ['rect', { width: '18', height: '18', x: '3', y: '4', rx: '2' }],
    ['circle', { cx: '12', cy: '10', r: '2' }],
    ['line', { x1: '8', x2: '8', y1: '2', y2: '4' }],
    ['line', { x1: '16', x2: '16', y1: '2', y2: '4' }],
  ],
];

export default Contact;
