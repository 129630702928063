import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name sun-snow
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgOWEzIDMgMCAxIDAgMCA2IiAvPgogIDxwYXRoIGQ9Ik0yIDEyaDEiIC8+CiAgPHBhdGggZD0iTTE0IDIxVjMiIC8+CiAgPHBhdGggZD0iTTEwIDRWMyIgLz4KICA8cGF0aCBkPSJNMTAgMjF2LTEiIC8+CiAgPHBhdGggZD0ibTMuNjQgMTguMzYuNy0uNyIgLz4KICA8cGF0aCBkPSJtNC4zNCA2LjM0LS43LS43IiAvPgogIDxwYXRoIGQ9Ik0xNCAxMmg4IiAvPgogIDxwYXRoIGQ9Im0xNyA0LTMgMyIgLz4KICA8cGF0aCBkPSJtMTQgMTcgMyAzIiAvPgogIDxwYXRoIGQ9Im0yMSAxNS0zLTMgMy0zIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/sun-snow
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SunSnow: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 9a3 3 0 1 0 0 6' }],
    ['path', { d: 'M2 12h1' }],
    ['path', { d: 'M14 21V3' }],
    ['path', { d: 'M10 4V3' }],
    ['path', { d: 'M10 21v-1' }],
    ['path', { d: 'm3.64 18.36.7-.7' }],
    ['path', { d: 'm4.34 6.34-.7-.7' }],
    ['path', { d: 'M14 12h8' }],
    ['path', { d: 'm17 4-3 3' }],
    ['path', { d: 'm14 17 3 3' }],
    ['path', { d: 'm21 15-3-3 3-3' }],
  ],
];

export default SunSnow;
