import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name wand
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUgNFYyIiAvPgogIDxwYXRoIGQ9Ik0xNSAxNnYtMiIgLz4KICA8cGF0aCBkPSJNOCA5aDIiIC8+CiAgPHBhdGggZD0iTTIwIDloMiIgLz4KICA8cGF0aCBkPSJNMTcuOCAxMS44IDE5IDEzIiAvPgogIDxwYXRoIGQ9Ik0xNSA5aDAiIC8+CiAgPHBhdGggZD0iTTE3LjggNi4yIDE5IDUiIC8+CiAgPHBhdGggZD0ibTMgMjEgOS05IiAvPgogIDxwYXRoIGQ9Ik0xMi4yIDYuMiAxMSA1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/wand
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Wand: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M15 4V2' }],
    ['path', { d: 'M15 16v-2' }],
    ['path', { d: 'M8 9h2' }],
    ['path', { d: 'M20 9h2' }],
    ['path', { d: 'M17.8 11.8 19 13' }],
    ['path', { d: 'M15 9h0' }],
    ['path', { d: 'M17.8 6.2 19 5' }],
    ['path', { d: 'm3 21 9-9' }],
    ['path', { d: 'M12.2 6.2 11 5' }],
  ],
];

export default Wand;
