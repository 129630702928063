import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name satellite-dish
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAxMGE3LjMxIDcuMzEgMCAwIDAgMTAgMTBaIiAvPgogIDxwYXRoIGQ9Im05IDE1IDMtMyIgLz4KICA8cGF0aCBkPSJNMTcgMTNhNiA2IDAgMCAwLTYtNiIgLz4KICA8cGF0aCBkPSJNMjEgMTNBMTAgMTAgMCAwIDAgMTEgMyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/satellite-dish
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SatelliteDish: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 10a7.31 7.31 0 0 0 10 10Z' }],
    ['path', { d: 'm9 15 3-3' }],
    ['path', { d: 'M17 13a6 6 0 0 0-6-6' }],
    ['path', { d: 'M21 13A10 10 0 0 0 11 3' }],
  ],
];

export default SatelliteDish;
