import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name sofa
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjAgOVY2YTIgMiAwIDAgMC0yLTJINmEyIDIgMCAwIDAtMiAydjMiIC8+CiAgPHBhdGggZD0iTTIgMTF2NWEyIDIgMCAwIDAgMiAyaDE2YTIgMiAwIDAgMCAyLTJ2LTVhMiAyIDAgMCAwLTQgMHYySDZ2LTJhMiAyIDAgMCAwLTQgMFoiIC8+CiAgPHBhdGggZD0iTTQgMTh2MiIgLz4KICA8cGF0aCBkPSJNMjAgMTh2MiIgLz4KICA8cGF0aCBkPSJNMTIgNHY5IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/sofa
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Sofa: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 9V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v3' }],
    [
      'path',
      { d: 'M2 11v5a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-5a2 2 0 0 0-4 0v2H6v-2a2 2 0 0 0-4 0Z' },
    ],
    ['path', { d: 'M4 18v2' }],
    ['path', { d: 'M20 18v2' }],
    ['path', { d: 'M12 4v9' }],
  ],
];

export default Sofa;
