import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name expand
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMjEgMjEtNi02bTYgNnYtNC44bTAgNC44aC00LjgiIC8+CiAgPHBhdGggZD0iTTMgMTYuMlYyMW0wIDBoNC44TTMgMjFsNi02IiAvPgogIDxwYXRoIGQ9Ik0yMSA3LjhWM20wIDBoLTQuOE0yMSAzbC02IDYiIC8+CiAgPHBhdGggZD0iTTMgNy44VjNtMCAwaDQuOE0zIDNsNiA2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/expand
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Expand: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm21 21-6-6m6 6v-4.8m0 4.8h-4.8' }],
    ['path', { d: 'M3 16.2V21m0 0h4.8M3 21l6-6' }],
    ['path', { d: 'M21 7.8V3m0 0h-4.8M21 3l-6 6' }],
    ['path', { d: 'M3 7.8V3m0 0h4.8M3 3l6 6' }],
  ],
];

export default Expand;
