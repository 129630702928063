import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name filter-x
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMuMDEzIDNIMmw4IDkuNDZWMTlsNCAydi04LjU0bC45LTEuMDU1IiAvPgogIDxwYXRoIGQ9Im0yMiAzLTUgNSIgLz4KICA8cGF0aCBkPSJtMTcgMyA1IDUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/filter-x
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FilterX: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M13.013 3H2l8 9.46V19l4 2v-8.54l.9-1.055' }],
    ['path', { d: 'm22 3-5 5' }],
    ['path', { d: 'm17 3 5 5' }],
  ],
];

export default FilterX;
