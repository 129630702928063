import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cog
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMjBhOCA4IDAgMSAwIDAtMTYgOCA4IDAgMCAwIDAgMTZaIiAvPgogIDxwYXRoIGQ9Ik0xMiAxNGEyIDIgMCAxIDAgMC00IDIgMiAwIDAgMCAwIDRaIiAvPgogIDxwYXRoIGQ9Ik0xMiAydjIiIC8+CiAgPHBhdGggZD0iTTEyIDIydi0yIiAvPgogIDxwYXRoIGQ9Im0xNyAyMC42Ni0xLTEuNzMiIC8+CiAgPHBhdGggZD0iTTExIDEwLjI3IDcgMy4zNCIgLz4KICA8cGF0aCBkPSJtMjAuNjYgMTctMS43My0xIiAvPgogIDxwYXRoIGQ9Im0zLjM0IDcgMS43MyAxIiAvPgogIDxwYXRoIGQ9Ik0xNCAxMmg4IiAvPgogIDxwYXRoIGQ9Ik0yIDEyaDIiIC8+CiAgPHBhdGggZD0ibTIwLjY2IDctMS43MyAxIiAvPgogIDxwYXRoIGQ9Im0zLjM0IDE3IDEuNzMtMSIgLz4KICA8cGF0aCBkPSJtMTcgMy4zNC0xIDEuNzMiIC8+CiAgPHBhdGggZD0ibTExIDEzLjczLTQgNi45MyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/cog
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Cog: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z' }],
    ['path', { d: 'M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z' }],
    ['path', { d: 'M12 2v2' }],
    ['path', { d: 'M12 22v-2' }],
    ['path', { d: 'm17 20.66-1-1.73' }],
    ['path', { d: 'M11 10.27 7 3.34' }],
    ['path', { d: 'm20.66 17-1.73-1' }],
    ['path', { d: 'm3.34 7 1.73 1' }],
    ['path', { d: 'M14 12h8' }],
    ['path', { d: 'M2 12h2' }],
    ['path', { d: 'm20.66 7-1.73 1' }],
    ['path', { d: 'm3.34 17 1.73-1' }],
    ['path', { d: 'm17 3.34-1 1.73' }],
    ['path', { d: 'm11 13.73-4 6.93' }],
  ],
];

export default Cog;
