import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name flip-vertical-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTcgMy01IDUtNS01aDEwIiAvPgogIDxwYXRoIGQ9Im0xNyAyMS01LTUtNSA1aDEwIiAvPgogIDxwYXRoIGQ9Ik00IDEySDIiIC8+CiAgPHBhdGggZD0iTTEwIDEySDgiIC8+CiAgPHBhdGggZD0iTTE2IDEyaC0yIiAvPgogIDxwYXRoIGQ9Ik0yMiAxMmgtMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/flip-vertical-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FlipVertical2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm17 3-5 5-5-5h10' }],
    ['path', { d: 'm17 21-5-5-5 5h10' }],
    ['path', { d: 'M4 12H2' }],
    ['path', { d: 'M10 12H8' }],
    ['path', { d: 'M16 12h-2' }],
    ['path', { d: 'M22 12h-2' }],
  ],
];

export default FlipVertical2;
