import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name nfc
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNiA4LjMyYTcuNDMgNy40MyAwIDAgMSAwIDcuMzYiIC8+CiAgPHBhdGggZD0iTTkuNDYgNi4yMWExMS43NiAxMS43NiAwIDAgMSAwIDExLjU4IiAvPgogIDxwYXRoIGQ9Ik0xMi45MSA0LjFhMTUuOTEgMTUuOTEgMCAwIDEgLjAxIDE1LjgiIC8+CiAgPHBhdGggZD0iTTE2LjM3IDJhMjAuMTYgMjAuMTYgMCAwIDEgMCAyMCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/nfc
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Nfc: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M6 8.32a7.43 7.43 0 0 1 0 7.36' }],
    ['path', { d: 'M9.46 6.21a11.76 11.76 0 0 1 0 11.58' }],
    ['path', { d: 'M12.91 4.1a15.91 15.91 0 0 1 .01 15.8' }],
    ['path', { d: 'M16.37 2a20.16 20.16 0 0 1 0 20' }],
  ],
];

export default Nfc;
