import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name map-pin-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNS40MyA1LjQzQTguMDYgOC4wNiAwIDAgMCA0IDEwYzAgNiA4IDEyIDggMTJhMjkuOTQgMjkuOTQgMCAwIDAgNS01IiAvPgogIDxwYXRoIGQ9Ik0xOS4xOCAxMy41MkE4LjY2IDguNjYgMCAwIDAgMjAgMTBhOCA4IDAgMCAwLTgtOCA3Ljg4IDcuODggMCAwIDAtMy41Mi44MiIgLz4KICA8cGF0aCBkPSJNOS4xMyA5LjEzQTIuNzggMi43OCAwIDAgMCA5IDEwYTMgMyAwIDAgMCAzIDMgMi43OCAyLjc4IDAgMCAwIC44Ny0uMTMiIC8+CiAgPHBhdGggZD0iTTE0LjkgOS4yNWEzIDMgMCAwIDAtMi4xNS0yLjE2IiAvPgogIDxsaW5lIHgxPSIyIiB4Mj0iMjIiIHkxPSIyIiB5Mj0iMjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/map-pin-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MapPinOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M5.43 5.43A8.06 8.06 0 0 0 4 10c0 6 8 12 8 12a29.94 29.94 0 0 0 5-5' }],
    ['path', { d: 'M19.18 13.52A8.66 8.66 0 0 0 20 10a8 8 0 0 0-8-8 7.88 7.88 0 0 0-3.52.82' }],
    ['path', { d: 'M9.13 9.13A2.78 2.78 0 0 0 9 10a3 3 0 0 0 3 3 2.78 2.78 0 0 0 .87-.13' }],
    ['path', { d: 'M14.9 9.25a3 3 0 0 0-2.15-2.16' }],
    ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
  ],
];

export default MapPinOff;
