import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name telescope
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTAuMDY1IDEyLjQ5My02LjE4IDEuMzE4YS45MzQuOTM0IDAgMCAxLTEuMTA4LS43MDJsLS41MzctMi4xNWExLjA3IDEuMDcgMCAwIDEgLjY5MS0xLjI2NWwxMy41MDQtNC40NCIgLz4KICA8cGF0aCBkPSJtMTMuNTYgMTEuNzQ3IDQuMzMyLS45MjQiIC8+CiAgPHBhdGggZD0ibTE2IDIxLTMuMTA1LTYuMjEiIC8+CiAgPHBhdGggZD0iTTE2LjQ4NSA1Ljk0YTIgMiAwIDAgMSAxLjQ1NS0yLjQyNWwxLjA5LS4yNzJhMSAxIDAgMCAxIDEuMjEyLjcyN2wxLjUxNSA2LjA2YTEgMSAwIDAgMS0uNzI3IDEuMjEzbC0xLjA5LjI3MmEyIDIgMCAwIDEtMi40MjUtMS40NTV6IiAvPgogIDxwYXRoIGQ9Im02LjE1OCA4LjYzMyAxLjExNCA0LjQ1NiIgLz4KICA8cGF0aCBkPSJtOCAyMSAzLjEwNS02LjIxIiAvPgogIDxjaXJjbGUgY3g9IjEyIiBjeT0iMTMiIHI9IjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/telescope
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Telescope: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm10.065 12.493-6.18 1.318a.934.934 0 0 1-1.108-.702l-.537-2.15a1.07 1.07 0 0 1 .691-1.265l13.504-4.44',
      },
    ],
    ['path', { d: 'm13.56 11.747 4.332-.924' }],
    ['path', { d: 'm16 21-3.105-6.21' }],
    [
      'path',
      {
        d: 'M16.485 5.94a2 2 0 0 1 1.455-2.425l1.09-.272a1 1 0 0 1 1.212.727l1.515 6.06a1 1 0 0 1-.727 1.213l-1.09.272a2 2 0 0 1-2.425-1.455z',
      },
    ],
    ['path', { d: 'm6.158 8.633 1.114 4.456' }],
    ['path', { d: 'm8 21 3.105-6.21' }],
    ['circle', { cx: '12', cy: '13', r: '2' }],
  ],
];

export default Telescope;
