import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name sparkle
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTIgMy0xLjkgNS44YTIgMiAwIDAgMS0xLjI4NyAxLjI4OEwzIDEybDUuOCAxLjlhMiAyIDAgMCAxIDEuMjg4IDEuMjg3TDEyIDIxbDEuOS01LjhhMiAyIDAgMCAxIDEuMjg3LTEuMjg4TDIxIDEybC01LjgtMS45YTIgMiAwIDAgMS0xLjI4OC0xLjI4N1oiIC8+Cjwvc3ZnPg==) - https://lucide.dev/icons/sparkle
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Sparkle: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm12 3-1.9 5.8a2 2 0 0 1-1.287 1.288L3 12l5.8 1.9a2 2 0 0 1 1.288 1.287L12 21l1.9-5.8a2 2 0 0 1 1.287-1.288L21 12l-5.8-1.9a2 2 0 0 1-1.288-1.287Z',
      },
    ],
  ],
];

export default Sparkle;
