import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name candy
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtOS41IDcuNS0yIDJhNC45NSA0Ljk1IDAgMSAwIDcgN2wyLTJhNC45NSA0Ljk1IDAgMSAwLTctN1oiIC8+CiAgPHBhdGggZD0iTTE0IDYuNXYxMCIgLz4KICA8cGF0aCBkPSJNMTAgNy41djEwIiAvPgogIDxwYXRoIGQ9Im0xNiA3IDEtNSAxLjM3LjY4QTMgMyAwIDAgMCAxOS43IDNIMjF2MS4zYzAgLjQ2LjEuOTIuMzIgMS4zM0wyMiA3bC01IDEiIC8+CiAgPHBhdGggZD0ibTggMTctMSA1LTEuMzctLjY4QTMgMyAwIDAgMCA0LjMgMjFIM3YtMS4zYTMgMyAwIDAgMC0uMzItMS4zM0wyIDE3bDUtMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/candy
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Candy: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm9.5 7.5-2 2a4.95 4.95 0 1 0 7 7l2-2a4.95 4.95 0 1 0-7-7Z' }],
    ['path', { d: 'M14 6.5v10' }],
    ['path', { d: 'M10 7.5v10' }],
    ['path', { d: 'm16 7 1-5 1.37.68A3 3 0 0 0 19.7 3H21v1.3c0 .46.1.92.32 1.33L22 7l-5 1' }],
    ['path', { d: 'm8 17-1 5-1.37-.68A3 3 0 0 0 4.3 21H3v-1.3a3 3 0 0 0-.32-1.33L2 17l5-1' }],
  ],
];

export default Candy;
