import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name moon-star
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgM2E2IDYgMCAwIDAgOSA5IDkgOSAwIDEgMS05LTlaIiAvPgogIDxwYXRoIGQ9Ik0xOSAzdjQiIC8+CiAgPHBhdGggZD0iTTIxIDVoLTQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/moon-star
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MoonStar: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z' }],
    ['path', { d: 'M19 3v4' }],
    ['path', { d: 'M21 5h-4' }],
  ],
];

export default MoonStar;
