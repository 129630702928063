import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name test-tube-diagonal
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgNyA2LjgyIDIxLjE4YTIuODMgMi44MyAwIDAgMS0zLjk5LS4wMXYwYTIuODMgMi44MyAwIDAgMSAwLTRMMTcgMyIgLz4KICA8cGF0aCBkPSJtMTYgMiA2IDYiIC8+CiAgPHBhdGggZD0iTTEyIDE2SDQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/test-tube-diagonal
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const TestTubeDiagonal: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 7 6.82 21.18a2.83 2.83 0 0 1-3.99-.01v0a2.83 2.83 0 0 1 0-4L17 3' }],
    ['path', { d: 'm16 2 6 6' }],
    ['path', { d: 'M12 16H4' }],
  ],
];

export default TestTubeDiagonal;
