import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name subscript
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNCA1IDggOCIgLz4KICA8cGF0aCBkPSJtMTIgNS04IDgiIC8+CiAgPHBhdGggZD0iTTIwIDE5aC00YzAtMS41LjQ0LTIgMS41LTIuNVMyMCAxNS4zMyAyMCAxNGMwLS40Ny0uMTctLjkzLS40OC0xLjI5YTIuMTEgMi4xMSAwIDAgMC0yLjYyLS40NGMtLjQyLjI0LS43NC42Mi0uOSAxLjA3IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/subscript
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Subscript: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm4 5 8 8' }],
    ['path', { d: 'm12 5-8 8' }],
    [
      'path',
      {
        d: 'M20 19h-4c0-1.5.44-2 1.5-2.5S20 15.33 20 14c0-.47-.17-.93-.48-1.29a2.11 2.11 0 0 0-2.62-.44c-.42.24-.74.62-.9 1.07',
      },
    ],
  ],
];

export default Subscript;
