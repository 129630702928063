import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name iteration-cw
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAxMGMwLTQuNCAzLjYtOCA4LThzOCAzLjYgOCA4LTMuNiA4LTggOEg0IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjggMjIgNCAxOCA4IDE0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/iteration-cw
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const IterationCw: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 10c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8H4' }],
    ['polyline', { points: '8 22 4 18 8 14' }],
  ],
];

export default IterationCw;
