import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cable
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcgMjF2LTJhMSAxIDAgMCAxLTEtMXYtMWEyIDIgMCAwIDEgMi0yaDJhMiAyIDAgMCAxIDIgMnYxYTEgMSAwIDAgMS0xIDEiIC8+CiAgPHBhdGggZD0iTTE5IDE1VjYuNWExIDEgMCAwIDAtNyAwdjExYTEgMSAwIDAgMS03IDBWOSIgLz4KICA8cGF0aCBkPSJNMjEgMjF2LTJoLTQiIC8+CiAgPHBhdGggZD0iTTMgNWg0VjMiIC8+CiAgPHBhdGggZD0iTTcgNWExIDEgMCAwIDEgMSAxdjFhMiAyIDAgMCAxLTIgMkg0YTIgMiAwIDAgMS0yLTJWNmExIDEgMCAwIDEgMS0xVjMiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/cable
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Cable: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M17 21v-2a1 1 0 0 1-1-1v-1a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v1a1 1 0 0 1-1 1' }],
    ['path', { d: 'M19 15V6.5a1 1 0 0 0-7 0v11a1 1 0 0 1-7 0V9' }],
    ['path', { d: 'M21 21v-2h-4' }],
    ['path', { d: 'M3 5h4V3' }],
    ['path', { d: 'M7 5a1 1 0 0 1 1 1v1a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a1 1 0 0 1 1-1V3' }],
  ],
];

export default Cable;
