import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cigarette
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTggMTJIMnY0aDE2IiAvPgogIDxwYXRoIGQ9Ik0yMiAxMnY0IiAvPgogIDxwYXRoIGQ9Ik03IDEydjQiIC8+CiAgPHBhdGggZD0iTTE4IDhjMC0yLjUtMi0yLjUtMi01IiAvPgogIDxwYXRoIGQ9Ik0yMiA4YzAtMi41LTItMi41LTItNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/cigarette
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Cigarette: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M18 12H2v4h16' }],
    ['path', { d: 'M22 12v4' }],
    ['path', { d: 'M7 12v4' }],
    ['path', { d: 'M18 8c0-2.5-2-2.5-2-5' }],
    ['path', { d: 'M22 8c0-2.5-2-2.5-2-5' }],
  ],
];

export default Cigarette;
