import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name swords
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSIxNC41IDE3LjUgMyA2IDMgMyA2IDMgMTcuNSAxNC41IiAvPgogIDxsaW5lIHgxPSIxMyIgeDI9IjE5IiB5MT0iMTkiIHkyPSIxMyIgLz4KICA8bGluZSB4MT0iMTYiIHgyPSIyMCIgeTE9IjE2IiB5Mj0iMjAiIC8+CiAgPGxpbmUgeDE9IjE5IiB4Mj0iMjEiIHkxPSIyMSIgeTI9IjE5IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjE0LjUgNi41IDE4IDMgMjEgMyAyMSA2IDE3LjUgOS41IiAvPgogIDxsaW5lIHgxPSI1IiB4Mj0iOSIgeTE9IjE0IiB5Mj0iMTgiIC8+CiAgPGxpbmUgeDE9IjciIHgyPSI0IiB5MT0iMTciIHkyPSIyMCIgLz4KICA8bGluZSB4MT0iMyIgeDI9IjUiIHkxPSIxOSIgeTI9IjIxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/swords
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Swords: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '14.5 17.5 3 6 3 3 6 3 17.5 14.5' }],
    ['line', { x1: '13', x2: '19', y1: '19', y2: '13' }],
    ['line', { x1: '16', x2: '20', y1: '16', y2: '20' }],
    ['line', { x1: '19', x2: '21', y1: '21', y2: '19' }],
    ['polyline', { points: '14.5 6.5 18 3 21 3 21 6 17.5 9.5' }],
    ['line', { x1: '5', x2: '9', y1: '14', y2: '18' }],
    ['line', { x1: '7', x2: '4', y1: '17', y2: '20' }],
    ['line', { x1: '3', x2: '5', y1: '19', y2: '21' }],
  ],
];

export default Swords;
