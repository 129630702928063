import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name slice
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtOCAxNC02IDZoOXYtMyIgLz4KICA8cGF0aCBkPSJNMTguMzcgMy42MyA4IDE0bDMgM0wyMS4zNyA2LjYzYTIuMTIgMi4xMiAwIDEgMC0zLTNaIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/slice
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Slice: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm8 14-6 6h9v-3' }],
    ['path', { d: 'M18.37 3.63 8 14l3 3L21.37 6.63a2.12 2.12 0 1 0-3-3Z' }],
  ],
];

export default Slice;
