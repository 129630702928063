import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name move-diagonal-2
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSI1IDExIDUgNSAxMSA1IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjE5IDEzIDE5IDE5IDEzIDE5IiAvPgogIDxsaW5lIHgxPSI1IiB4Mj0iMTkiIHkxPSI1IiB5Mj0iMTkiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/move-diagonal-2
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MoveDiagonal2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '5 11 5 5 11 5' }],
    ['polyline', { points: '19 13 19 19 13 19' }],
    ['line', { x1: '5', x2: '19', y1: '5', y2: '19' }],
  ],
];

export default MoveDiagonal2;
