import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name map-pinned
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTggOGMwIDQuNS02IDktNiA5cy02LTQuNS02LTlhNiA2IDAgMCAxIDEyIDAiIC8+CiAgPGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIyIiAvPgogIDxwYXRoIGQ9Ik04LjgzNSAxNEg1YTEgMSAwIDAgMC0uOS43bC0yIDZjLS4xLjEtLjEuMi0uMS4zIDAgLjYuNCAxIDEgMWgxOGMuNiAwIDEtLjQgMS0xIDAtLjEgMC0uMi0uMS0uM2wtMi02YTEgMSAwIDAgMC0uOS0uN2gtMy44MzUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/map-pinned
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MapPinned: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M18 8c0 4.5-6 9-6 9s-6-4.5-6-9a6 6 0 0 1 12 0' }],
    ['circle', { cx: '12', cy: '8', r: '2' }],
    [
      'path',
      {
        d: 'M8.835 14H5a1 1 0 0 0-.9.7l-2 6c-.1.1-.1.2-.1.3 0 .6.4 1 1 1h18c.6 0 1-.4 1-1 0-.1 0-.2-.1-.3l-2-6a1 1 0 0 0-.9-.7h-3.835',
      },
    ],
  ],
];

export default MapPinned;
