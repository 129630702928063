import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name thermometer-snowflake
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxMmgxMCIgLz4KICA8cGF0aCBkPSJNOSA0djE2IiAvPgogIDxwYXRoIGQ9Im0zIDkgMyAzLTMgMyIgLz4KICA8cGF0aCBkPSJNMTIgNiA5IDkgNiA2IiAvPgogIDxwYXRoIGQ9Im02IDE4IDMtMyAxLjUgMS41IiAvPgogIDxwYXRoIGQ9Ik0yMCA0djEwLjU0YTQgNCAwIDEgMS00IDBWNGEyIDIgMCAwIDEgNCAwWiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/thermometer-snowflake
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ThermometerSnowflake: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 12h10' }],
    ['path', { d: 'M9 4v16' }],
    ['path', { d: 'm3 9 3 3-3 3' }],
    ['path', { d: 'M12 6 9 9 6 6' }],
    ['path', { d: 'm6 18 3-3 1.5 1.5' }],
    ['path', { d: 'M20 4v10.54a4 4 0 1 1-4 0V4a2 2 0 0 1 4 0Z' }],
  ],
];

export default ThermometerSnowflake;
