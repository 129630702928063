import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name shrink
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTUgMTUgNiA2bS02LTZ2NC44bTAtNC44aDQuOCIgLz4KICA8cGF0aCBkPSJNOSAxOS44VjE1bTAgMEg0LjJNOSAxNWwtNiA2IiAvPgogIDxwYXRoIGQ9Ik0xNSA0LjJWOW0wIDBoNC44TTE1IDlsNi02IiAvPgogIDxwYXRoIGQ9Ik05IDQuMlY5bTAgMEg0LjJNOSA5IDMgMyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/shrink
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Shrink: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm15 15 6 6m-6-6v4.8m0-4.8h4.8' }],
    ['path', { d: 'M9 19.8V15m0 0H4.2M9 15l-6 6' }],
    ['path', { d: 'M15 4.2V9m0 0h4.8M15 9l6-6' }],
    ['path', { d: 'M9 4.2V9m0 0H4.2M9 9 3 3' }],
  ],
];

export default Shrink;
