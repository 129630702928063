import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name beef
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMi41IiBjeT0iOC41IiByPSIyLjUiIC8+CiAgPHBhdGggZD0iTTEyLjUgMmE2LjUgNi41IDAgMCAwLTYuMjIgNC42Yy0xLjEgMy4xMy0uNzggMy45LTMuMTggNi4wOEEzIDMgMCAwIDAgNSAxOGM0IDAgOC40LTEuOCAxMS40LTQuM0E2LjUgNi41IDAgMCAwIDEyLjUgMloiIC8+CiAgPHBhdGggZD0ibTE4LjUgNiAyLjE5IDQuNWE2LjQ4IDYuNDggMCAwIDEgLjMxIDIgNi40OSA2LjQ5IDAgMCAxLTIuNiA1LjJDMTUuNCAyMC4yIDExIDIyIDcgMjJhMyAzIDAgMCAxLTIuNjgtMS42NkwyLjQgMTYuNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/beef
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Beef: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12.5', cy: '8.5', r: '2.5' }],
    [
      'path',
      {
        d: 'M12.5 2a6.5 6.5 0 0 0-6.22 4.6c-1.1 3.13-.78 3.9-3.18 6.08A3 3 0 0 0 5 18c4 0 8.4-1.8 11.4-4.3A6.5 6.5 0 0 0 12.5 2Z',
      },
    ],
    [
      'path',
      {
        d: 'm18.5 6 2.19 4.5a6.48 6.48 0 0 1 .31 2 6.49 6.49 0 0 1-2.6 5.2C15.4 20.2 11 22 7 22a3 3 0 0 1-2.68-1.66L2.4 16.5',
      },
    ],
  ],
];

export default Beef;
