import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name dumbbell
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNi41IDYuNSAxMSAxMSIgLz4KICA8cGF0aCBkPSJtMjEgMjEtMS0xIiAvPgogIDxwYXRoIGQ9Im0zIDMgMSAxIiAvPgogIDxwYXRoIGQ9Im0xOCAyMiA0LTQiIC8+CiAgPHBhdGggZD0ibTIgNiA0LTQiIC8+CiAgPHBhdGggZD0ibTMgMTAgNy03IiAvPgogIDxwYXRoIGQ9Im0xNCAyMSA3LTciIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/dumbbell
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Dumbbell: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm6.5 6.5 11 11' }],
    ['path', { d: 'm21 21-1-1' }],
    ['path', { d: 'm3 3 1 1' }],
    ['path', { d: 'm18 22 4-4' }],
    ['path', { d: 'm2 6 4-4' }],
    ['path', { d: 'm3 10 7-7' }],
    ['path', { d: 'm14 21 7-7' }],
  ],
];

export default Dumbbell;
