import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name align-horizontal-space-between
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iNiIgaGVpZ2h0PSIxNCIgeD0iMyIgeT0iNSIgcng9IjIiIC8+CiAgPHJlY3Qgd2lkdGg9IjYiIGhlaWdodD0iMTAiIHg9IjE1IiB5PSI3IiByeD0iMiIgLz4KICA8cGF0aCBkPSJNMyAydjIwIiAvPgogIDxwYXRoIGQ9Ik0yMSAydjIwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/align-horizontal-space-between
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const AlignHorizontalSpaceBetween: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '6', height: '14', x: '3', y: '5', rx: '2' }],
    ['rect', { width: '6', height: '10', x: '15', y: '7', rx: '2' }],
    ['path', { d: 'M3 2v20' }],
    ['path', { d: 'M21 2v20' }],
  ],
];

export default AlignHorizontalSpaceBetween;
