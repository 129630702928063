import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name washing-machine
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyA2aDMiIC8+CiAgPHBhdGggZD0iTTE3IDZoLjAxIiAvPgogIDxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIyMCIgeD0iMyIgeT0iMiIgcng9IjIiIC8+CiAgPGNpcmNsZSBjeD0iMTIiIGN5PSIxMyIgcj0iNSIgLz4KICA8cGF0aCBkPSJNMTIgMThhMi41IDIuNSAwIDAgMCAwLTUgMi41IDIuNSAwIDAgMSAwLTUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/washing-machine
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const WashingMachine: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 6h3' }],
    ['path', { d: 'M17 6h.01' }],
    ['rect', { width: '18', height: '20', x: '3', y: '2', rx: '2' }],
    ['circle', { cx: '12', cy: '13', r: '5' }],
    ['path', { d: 'M12 18a2.5 2.5 0 0 0 0-5 2.5 2.5 0 0 1 0-5' }],
  ],
];

export default WashingMachine;
