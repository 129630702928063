import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name church
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTggNyA0IDJ2MTFhMiAyIDAgMCAxLTIgMkg0YTIgMiAwIDAgMS0yLTJWOWw0LTIiIC8+CiAgPHBhdGggZD0iTTE0IDIydi00YTIgMiAwIDAgMC0yLTJ2MGEyIDIgMCAwIDAtMiAydjQiIC8+CiAgPHBhdGggZD0iTTE4IDIyVjVsLTYtMy02IDN2MTciIC8+CiAgPHBhdGggZD0iTTEyIDd2NSIgLz4KICA8cGF0aCBkPSJNMTAgOWg0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/church
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Church: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm18 7 4 2v11a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9l4-2' }],
    ['path', { d: 'M14 22v-4a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v4' }],
    ['path', { d: 'M18 22V5l-6-3-6 3v17' }],
    ['path', { d: 'M12 7v5' }],
    ['path', { d: 'M10 9h4' }],
  ],
];

export default Church;
