import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name replace-all
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgNGMwLTEuMS45LTIgMi0yIiAvPgogIDxwYXRoIGQ9Ik0yMCAyYzEuMSAwIDIgLjkgMiAyIiAvPgogIDxwYXRoIGQ9Ik0yMiA4YzAgMS4xLS45IDItMiAyIiAvPgogIDxwYXRoIGQ9Ik0xNiAxMGMtMS4xIDAtMi0uOS0yLTIiIC8+CiAgPHBhdGggZD0ibTMgNyAzIDMgMy0zIiAvPgogIDxwYXRoIGQ9Ik02IDEwVjVjMC0xLjcgMS4zLTMgMy0zaDEiIC8+CiAgPHJlY3Qgd2lkdGg9IjgiIGhlaWdodD0iOCIgeD0iMiIgeT0iMTQiIHJ4PSIyIiAvPgogIDxwYXRoIGQ9Ik0xNCAxNGMxLjEgMCAyIC45IDIgMnY0YzAgMS4xLS45IDItMiAyIiAvPgogIDxwYXRoIGQ9Ik0yMCAxNGMxLjEgMCAyIC45IDIgMnY0YzAgMS4xLS45IDItMiAyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/replace-all
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ReplaceAll: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 4c0-1.1.9-2 2-2' }],
    ['path', { d: 'M20 2c1.1 0 2 .9 2 2' }],
    ['path', { d: 'M22 8c0 1.1-.9 2-2 2' }],
    ['path', { d: 'M16 10c-1.1 0-2-.9-2-2' }],
    ['path', { d: 'm3 7 3 3 3-3' }],
    ['path', { d: 'M6 10V5c0-1.7 1.3-3 3-3h1' }],
    ['rect', { width: '8', height: '8', x: '2', y: '14', rx: '2' }],
    ['path', { d: 'M14 14c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2' }],
    ['path', { d: 'M20 14c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2' }],
  ],
];

export default ReplaceAll;
