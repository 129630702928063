import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name package-open
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMjJ2LTkiIC8+CiAgPHBhdGggZD0iTTE1LjE3IDIuMjFhMS42NyAxLjY3IDAgMCAxIDEuNjMgMEwyMSA0LjU3YTEuOTMgMS45MyAwIDAgMSAwIDMuMzZMOC44MiAxNC43OWExLjY1NSAxLjY1NSAwIDAgMS0xLjY0IDBMMyAxMi40M2ExLjkzIDEuOTMgMCAwIDEgMC0zLjM2eiIgLz4KICA8cGF0aCBkPSJNMjAgMTN2My44N2EyLjA2IDIuMDYgMCAwIDEtMS4xMSAxLjgzbC02IDMuMDhhMS45MyAxLjkzIDAgMCAxLTEuNzggMGwtNi0zLjA4QTIuMDYgMi4wNiAwIDAgMSA0IDE2Ljg3VjEzIiAvPgogIDxwYXRoIGQ9Ik0yMSAxMi40M2ExLjkzIDEuOTMgMCAwIDAgMC0zLjM2TDguODMgMi4yYTEuNjQgMS42NCAwIDAgMC0xLjYzIDBMMyA0LjU3YTEuOTMgMS45MyAwIDAgMCAwIDMuMzZsMTIuMTggNi44NmExLjYzNiAxLjYzNiAwIDAgMCAxLjYzIDB6IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/package-open
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PackageOpen: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 22v-9' }],
    [
      'path',
      {
        d: 'M15.17 2.21a1.67 1.67 0 0 1 1.63 0L21 4.57a1.93 1.93 0 0 1 0 3.36L8.82 14.79a1.655 1.655 0 0 1-1.64 0L3 12.43a1.93 1.93 0 0 1 0-3.36z',
      },
    ],
    [
      'path',
      {
        d: 'M20 13v3.87a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13',
      },
    ],
    [
      'path',
      {
        d: 'M21 12.43a1.93 1.93 0 0 0 0-3.36L8.83 2.2a1.64 1.64 0 0 0-1.63 0L3 4.57a1.93 1.93 0 0 0 0 3.36l12.18 6.86a1.636 1.636 0 0 0 1.63 0z',
      },
    ],
  ],
];

export default PackageOpen;
