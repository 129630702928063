import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name link-2-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSAxN0g3QTUgNSAwIDAgMSA3IDciIC8+CiAgPHBhdGggZD0iTTE1IDdoMmE1IDUgMCAwIDEgNCA4IiAvPgogIDxsaW5lIHgxPSI4IiB4Mj0iMTIiIHkxPSIxMiIgeTI9IjEyIiAvPgogIDxsaW5lIHgxPSIyIiB4Mj0iMjIiIHkxPSIyIiB5Mj0iMjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/link-2-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Link2Off: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M9 17H7A5 5 0 0 1 7 7' }],
    ['path', { d: 'M15 7h2a5 5 0 0 1 4 8' }],
    ['line', { x1: '8', x2: '12', y1: '12', y2: '12' }],
    ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
  ],
];

export default Link2Off;
