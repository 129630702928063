import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name backpack
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAxMGE0IDQgMCAwIDEgNC00aDhhNCA0IDAgMCAxIDQgNHYxMGEyIDIgMCAwIDEtMiAySDZhMiAyIDAgMCAxLTItMloiIC8+CiAgPHBhdGggZD0iTTkgNlY0YTIgMiAwIDAgMSAyLTJoMmEyIDIgMCAwIDEgMiAydjIiIC8+CiAgPHBhdGggZD0iTTggMjF2LTVhMiAyIDAgMCAxIDItMmg0YTIgMiAwIDAgMSAyIDJ2NSIgLz4KICA8cGF0aCBkPSJNOCAxMGg4IiAvPgogIDxwYXRoIGQ9Ik04IDE4aDgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/backpack
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Backpack: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 10a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2Z' }],
    ['path', { d: 'M9 6V4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2' }],
    ['path', { d: 'M8 21v-5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v5' }],
    ['path', { d: 'M8 10h8' }],
    ['path', { d: 'M8 18h8' }],
  ],
];

export default Backpack;
