import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name castle
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjIgMjB2LTlIMnY5YTIgMiAwIDAgMCAyIDJoMTZhMiAyIDAgMCAwIDItMloiIC8+CiAgPHBhdGggZD0iTTE4IDExVjRINnY3IiAvPgogIDxwYXRoIGQ9Ik0xNSAyMnYtNGEzIDMgMCAwIDAtMy0zdjBhMyAzIDAgMCAwLTMgM3Y0IiAvPgogIDxwYXRoIGQ9Ik0yMiAxMVY5IiAvPgogIDxwYXRoIGQ9Ik0yIDExVjkiIC8+CiAgPHBhdGggZD0iTTYgNFYyIiAvPgogIDxwYXRoIGQ9Ik0xOCA0VjIiIC8+CiAgPHBhdGggZD0iTTEwIDRWMiIgLz4KICA8cGF0aCBkPSJNMTQgNFYyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/castle
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Castle: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M22 20v-9H2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2Z' }],
    ['path', { d: 'M18 11V4H6v7' }],
    ['path', { d: 'M15 22v-4a3 3 0 0 0-3-3v0a3 3 0 0 0-3 3v4' }],
    ['path', { d: 'M22 11V9' }],
    ['path', { d: 'M2 11V9' }],
    ['path', { d: 'M6 4V2' }],
    ['path', { d: 'M18 4V2' }],
    ['path', { d: 'M10 4V2' }],
    ['path', { d: 'M14 4V2' }],
  ],
];

export default Castle;
