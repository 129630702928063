import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name umbrella-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMnYxIiAvPgogIDxwYXRoIGQ9Ik0xNS41IDIxYTEuODUgMS44NSAwIDAgMS0zLjUtMXYtOEgyYTEwIDEwIDAgMCAxIDMuNDI4LTYuNTc1IiAvPgogIDxwYXRoIGQ9Ik0xNy41IDEySDIyQTEwIDEwIDAgMCAwIDkuMDA0IDMuNDU1IiAvPgogIDxwYXRoIGQ9Im0yIDIgMjAgMjAiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/umbrella-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const UmbrellaOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 2v1' }],
    ['path', { d: 'M15.5 21a1.85 1.85 0 0 1-3.5-1v-8H2a10 10 0 0 1 3.428-6.575' }],
    ['path', { d: 'M17.5 12H22A10 10 0 0 0 9.004 3.455' }],
    ['path', { d: 'm2 2 20 20' }],
  ],
];

export default UmbrellaOff;
