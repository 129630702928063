import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name indent-decrease
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSI3IDggMyAxMiA3IDE2IiAvPgogIDxsaW5lIHgxPSIyMSIgeDI9IjExIiB5MT0iMTIiIHkyPSIxMiIgLz4KICA8bGluZSB4MT0iMjEiIHgyPSIxMSIgeTE9IjYiIHkyPSI2IiAvPgogIDxsaW5lIHgxPSIyMSIgeDI9IjExIiB5MT0iMTgiIHkyPSIxOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/indent-decrease
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const IndentDecrease: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '7 8 3 12 7 16' }],
    ['line', { x1: '21', x2: '11', y1: '12', y2: '12' }],
    ['line', { x1: '21', x2: '11', y1: '6', y2: '6' }],
    ['line', { x1: '21', x2: '11', y1: '18', y2: '18' }],
  ],
];

export default IndentDecrease;
