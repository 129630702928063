import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name book-image
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAxOS41di0xNUEyLjUgMi41IDAgMCAxIDYuNSAySDIwdjIwSDYuNWEyLjUgMi41IDAgMCAxIDAtNUgyMCIgLz4KICA8Y2lyY2xlIGN4PSIxMCIgY3k9IjgiIHI9IjIiIC8+CiAgPHBhdGggZD0ibTIwIDEzLjctMi4xLTIuMWMtLjgtLjgtMi0uOC0yLjggMEw5LjcgMTciIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/book-image
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BookImage: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20' }],
    ['circle', { cx: '10', cy: '8', r: '2' }],
    ['path', { d: 'm20 13.7-2.1-2.1c-.8-.8-2-.8-2.8 0L9.7 17' }],
  ],
];

export default BookImage;
