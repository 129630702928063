import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name server-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyAyaDEzYTIgMiAwIDAgMSAyIDJ2NGEyIDIgMCAwIDEtMiAyaC01IiAvPgogIDxwYXRoIGQ9Ik0xMCAxMCAyLjUgMi41QzIgMiAyIDIuNSAyIDV2M2EyIDIgMCAwIDAgMiAyaDZ6IiAvPgogIDxwYXRoIGQ9Ik0yMiAxN3YtMWEyIDIgMCAwIDAtMi0yaC0xIiAvPgogIDxwYXRoIGQ9Ik00IDE0YTIgMiAwIDAgMC0yIDJ2NGEyIDIgMCAwIDAgMiAyaDE2LjVsMS0uNS41LjUtOC04SDR6IiAvPgogIDxwYXRoIGQ9Ik02IDE4aC4wMSIgLz4KICA8cGF0aCBkPSJtMiAyIDIwIDIwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/server-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ServerOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7 2h13a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-5' }],
    ['path', { d: 'M10 10 2.5 2.5C2 2 2 2.5 2 5v3a2 2 0 0 0 2 2h6z' }],
    ['path', { d: 'M22 17v-1a2 2 0 0 0-2-2h-1' }],
    ['path', { d: 'M4 14a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16.5l1-.5.5.5-8-8H4z' }],
    ['path', { d: 'M6 18h.01' }],
    ['path', { d: 'm2 2 20 20' }],
  ],
];

export default ServerOff;
