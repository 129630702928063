import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name webhook-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcgMTdoLTVjLTEuMDktLjAyLTEuOTQuOTItMi41IDEuOUEzIDMgMCAxIDEgMi41NyAxNSIgLz4KICA8cGF0aCBkPSJNOSAzLjRhNCA0IDAgMCAxIDYuNTIuNjYiIC8+CiAgPHBhdGggZD0ibTYgMTcgMy4xLTUuOGEyLjUgMi41IDAgMCAwIC4wNTctMi4wNSIgLz4KICA8cGF0aCBkPSJNMjAuMyAyMC4zYTQgNCAwIDAgMS0yLjMuNyIgLz4KICA8cGF0aCBkPSJNMTguNiAxM2E0IDQgMCAwIDEgMy4zNTcgMy40MTQiIC8+CiAgPHBhdGggZD0ibTEyIDYgLjYgMSIgLz4KICA8cGF0aCBkPSJtMiAyIDIwIDIwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/webhook-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const WebhookOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M17 17h-5c-1.09-.02-1.94.92-2.5 1.9A3 3 0 1 1 2.57 15' }],
    ['path', { d: 'M9 3.4a4 4 0 0 1 6.52.66' }],
    ['path', { d: 'm6 17 3.1-5.8a2.5 2.5 0 0 0 .057-2.05' }],
    ['path', { d: 'M20.3 20.3a4 4 0 0 1-2.3.7' }],
    ['path', { d: 'M18.6 13a4 4 0 0 1 3.357 3.414' }],
    ['path', { d: 'm12 6 .6 1' }],
    ['path', { d: 'm2 2 20 20' }],
  ],
];

export default WebhookOff;
