import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name save-all
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNiA0YTIgMiAwIDAgMSAyLTJoMTBsNCA0djEwLjJhMiAyIDAgMCAxLTIgMS44SDhhMiAyIDAgMCAxLTItMloiIC8+CiAgPHBhdGggZD0iTTEwIDJ2NGg2IiAvPgogIDxwYXRoIGQ9Ik0xOCAxOHYtN2gtOHY3IiAvPgogIDxwYXRoIGQ9Ik0xOCAyMkg0YTIgMiAwIDAgMS0yLTJWNiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/save-all
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SaveAll: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M6 4a2 2 0 0 1 2-2h10l4 4v10.2a2 2 0 0 1-2 1.8H8a2 2 0 0 1-2-2Z' }],
    ['path', { d: 'M10 2v4h6' }],
    ['path', { d: 'M18 18v-7h-8v7' }],
    ['path', { d: 'M18 22H4a2 2 0 0 1-2-2V6' }],
  ],
];

export default SaveAll;
