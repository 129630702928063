import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name move
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWxpbmUgcG9pbnRzPSI1IDkgMiAxMiA1IDE1IiAvPgogIDxwb2x5bGluZSBwb2ludHM9IjkgNSAxMiAyIDE1IDUiIC8+CiAgPHBvbHlsaW5lIHBvaW50cz0iMTUgMTkgMTIgMjIgOSAxOSIgLz4KICA8cG9seWxpbmUgcG9pbnRzPSIxOSA5IDIyIDEyIDE5IDE1IiAvPgogIDxsaW5lIHgxPSIyIiB4Mj0iMjIiIHkxPSIxMiIgeTI9IjEyIiAvPgogIDxsaW5lIHgxPSIxMiIgeDI9IjEyIiB5MT0iMiIgeTI9IjIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/move
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Move: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '5 9 2 12 5 15' }],
    ['polyline', { points: '9 5 12 2 15 5' }],
    ['polyline', { points: '15 19 12 22 9 19' }],
    ['polyline', { points: '19 9 22 12 19 15' }],
    ['line', { x1: '2', x2: '22', y1: '12', y2: '12' }],
    ['line', { x1: '12', x2: '12', y1: '2', y2: '22' }],
  ],
];

export default Move;
