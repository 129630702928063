import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name leafy-green
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAyMmMxLjI1LS45ODcgMi4yNy0xLjk3NSAzLjktMi4yYTUuNTYgNS41NiAwIDAgMSAzLjggMS41IDQgNCAwIDAgMCA2LjE4Ny0yLjM1MyAzLjUgMy41IDAgMCAwIDMuNjktNS4xMTZBMy41IDMuNSAwIDAgMCAyMC45NSA4IDMuNSAzLjUgMCAxIDAgMTYgMy4wNWEzLjUgMy41IDAgMCAwLTUuODMxIDEuMzczIDMuNSAzLjUgMCAwIDAtNS4xMTYgMy42OSA0IDQgMCAwIDAtMi4zNDggNi4xNTVDMy40OTkgMTUuNDIgNC40MDkgMTYuNzEyIDQuMiAxOC4xIDMuOTI2IDE5Ljc0MyAzLjAxNCAyMC43MzIgMiAyMiIgLz4KICA8cGF0aCBkPSJNMiAyMiAxNyA3IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/leafy-green
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const LeafyGreen: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M2 22c1.25-.987 2.27-1.975 3.9-2.2a5.56 5.56 0 0 1 3.8 1.5 4 4 0 0 0 6.187-2.353 3.5 3.5 0 0 0 3.69-5.116A3.5 3.5 0 0 0 20.95 8 3.5 3.5 0 1 0 16 3.05a3.5 3.5 0 0 0-5.831 1.373 3.5 3.5 0 0 0-5.116 3.69 4 4 0 0 0-2.348 6.155C3.499 15.42 4.409 16.712 4.2 18.1 3.926 19.743 3.014 20.732 2 22',
      },
    ],
    ['path', { d: 'M2 22 17 7' }],
  ],
];

export default LeafyGreen;
