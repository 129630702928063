import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name magnet
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNiAxNS00LTQgNi43NS02Ljc3YTcuNzkgNy43OSAwIDAgMSAxMSAxMUwxMyAyMmwtNC00IDYuMzktNi4zNmEyLjE0IDIuMTQgMCAwIDAtMy0zTDYgMTUiIC8+CiAgPHBhdGggZD0ibTUgOCA0IDQiIC8+CiAgPHBhdGggZD0ibTEyIDE1IDQgNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/magnet
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Magnet: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm6 15-4-4 6.75-6.77a7.79 7.79 0 0 1 11 11L13 22l-4-4 6.39-6.36a2.14 2.14 0 0 0-3-3L6 15',
      },
    ],
    ['path', { d: 'm5 8 4 4' }],
    ['path', { d: 'm12 15 4 4' }],
  ],
];

export default Magnet;
