import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name message-square-quote
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgMTVhMiAyIDAgMCAxLTIgMkg3bC00IDRWNWEyIDIgMCAwIDEgMi0yaDE0YTIgMiAwIDAgMSAyIDJ6IiAvPgogIDxwYXRoIGQ9Ik04IDEyYTIgMiAwIDAgMCAyLTJWOEg4IiAvPgogIDxwYXRoIGQ9Ik0xNCAxMmEyIDIgMCAwIDAgMi0yVjhoLTIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/message-square-quote
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MessageSquareQuote: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z' }],
    ['path', { d: 'M8 12a2 2 0 0 0 2-2V8H8' }],
    ['path', { d: 'M14 12a2 2 0 0 0 2-2V8h-2' }],
  ],
];

export default MessageSquareQuote;
