import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name folder-pen
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOC40IDEwLjZhMiAyIDAgMCAxIDMgM0w2IDE5bC00IDEgMS00WiIgLz4KICA8cGF0aCBkPSJNMiAxMS41VjVhMiAyIDAgMCAxIDItMmgzLjljLjcgMCAxLjMuMyAxLjcuOWwuOCAxLjJjLjQuNiAxIC45IDEuNy45SDIwYTIgMiAwIDAgMSAyIDJ2MTBhMiAyIDAgMCAxLTIgMmgtOS41IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/folder-pen
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FolderPen: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M8.4 10.6a2 2 0 0 1 3 3L6 19l-4 1 1-4Z' }],
    [
      'path',
      {
        d: 'M2 11.5V5a2 2 0 0 1 2-2h3.9c.7 0 1.3.3 1.7.9l.8 1.2c.4.6 1 .9 1.7.9H20a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-9.5',
      },
    ],
  ],
];

export default FolderPen;
