import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name paint-bucket
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTkgMTEtOC04LTguNiA4LjZhMiAyIDAgMCAwIDAgMi44bDUuMiA1LjJjLjguOCAyIC44IDIuOCAwTDE5IDExWiIgLz4KICA8cGF0aCBkPSJtNSAyIDUgNSIgLz4KICA8cGF0aCBkPSJNMiAxM2gxNSIgLz4KICA8cGF0aCBkPSJNMjIgMjBhMiAyIDAgMSAxLTQgMGMwLTEuNiAxLjctMi40IDItNCAuMyAxLjYgMiAyLjQgMiA0WiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/paint-bucket
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PaintBucket: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm19 11-8-8-8.6 8.6a2 2 0 0 0 0 2.8l5.2 5.2c.8.8 2 .8 2.8 0L19 11Z' }],
    ['path', { d: 'm5 2 5 5' }],
    ['path', { d: 'M2 13h15' }],
    ['path', { d: 'M22 20a2 2 0 1 1-4 0c0-1.6 1.7-2.4 2-4 .3 1.6 2 2.4 2 4Z' }],
  ],
];

export default PaintBucket;
