import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name pencil-ruler
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTUgNSA0IDQiIC8+CiAgPHBhdGggZD0iTTEzIDcgOC43IDIuN2EyLjQxIDIuNDEgMCAwIDAtMy40IDBMMi43IDUuM2EyLjQxIDIuNDEgMCAwIDAgMCAzLjRMNyAxMyIgLz4KICA8cGF0aCBkPSJtOCA2IDItMiIgLz4KICA8cGF0aCBkPSJtMiAyMiA1LjUtMS41TDIxLjE3IDYuODNhMi44MiAyLjgyIDAgMCAwLTQtNEwzLjUgMTYuNVoiIC8+CiAgPHBhdGggZD0ibTE4IDE2IDItMiIgLz4KICA8cGF0aCBkPSJtMTcgMTEgNC4zIDQuM2MuOTQuOTQuOTQgMi40NiAwIDMuNGwtMi42IDIuNmMtLjk0Ljk0LTIuNDYuOTQtMy40IDBMMTEgMTciIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/pencil-ruler
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const PencilRuler: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm15 5 4 4' }],
    ['path', { d: 'M13 7 8.7 2.7a2.41 2.41 0 0 0-3.4 0L2.7 5.3a2.41 2.41 0 0 0 0 3.4L7 13' }],
    ['path', { d: 'm8 6 2-2' }],
    ['path', { d: 'm2 22 5.5-1.5L21.17 6.83a2.82 2.82 0 0 0-4-4L3.5 16.5Z' }],
    ['path', { d: 'm18 16 2-2' }],
    ['path', { d: 'm17 11 4.3 4.3c.94.94.94 2.46 0 3.4l-2.6 2.6c-.94.94-2.46.94-3.4 0L11 17' }],
  ],
];

export default PencilRuler;
