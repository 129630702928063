import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name server-cog
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIzIiAvPgogIDxwYXRoIGQ9Ik00LjUgMTBINGEyIDIgMCAwIDEtMi0yVjRhMiAyIDAgMCAxIDItMmgxNmEyIDIgMCAwIDEgMiAydjRhMiAyIDAgMCAxLTIgMmgtLjUiIC8+CiAgPHBhdGggZD0iTTQuNSAxNEg0YTIgMiAwIDAgMC0yIDJ2NGEyIDIgMCAwIDAgMiAyaDE2YTIgMiAwIDAgMCAyLTJ2LTRhMiAyIDAgMCAwLTItMmgtLjUiIC8+CiAgPHBhdGggZD0iTTYgNmguMDEiIC8+CiAgPHBhdGggZD0iTTYgMThoLjAxIiAvPgogIDxwYXRoIGQ9Im0xNS43IDEzLjQtLjktLjMiIC8+CiAgPHBhdGggZD0ibTkuMiAxMC45LS45LS4zIiAvPgogIDxwYXRoIGQ9Im0xMC42IDE1LjcuMy0uOSIgLz4KICA8cGF0aCBkPSJtMTMuNiAxNS43LS40LTEiIC8+CiAgPHBhdGggZD0ibTEwLjggOS4zLS40LTEiIC8+CiAgPHBhdGggZD0ibTguMyAxMy42IDEtLjQiIC8+CiAgPHBhdGggZD0ibTE0LjcgMTAuOCAxLS40IiAvPgogIDxwYXRoIGQ9Im0xMy40IDguMy0uMy45IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/server-cog
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ServerCog: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '12', r: '3' }],
    ['path', { d: 'M4.5 10H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-.5' }],
    [
      'path',
      { d: 'M4.5 14H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-.5' },
    ],
    ['path', { d: 'M6 6h.01' }],
    ['path', { d: 'M6 18h.01' }],
    ['path', { d: 'm15.7 13.4-.9-.3' }],
    ['path', { d: 'm9.2 10.9-.9-.3' }],
    ['path', { d: 'm10.6 15.7.3-.9' }],
    ['path', { d: 'm13.6 15.7-.4-1' }],
    ['path', { d: 'm10.8 9.3-.4-1' }],
    ['path', { d: 'm8.3 13.6 1-.4' }],
    ['path', { d: 'm14.7 10.8 1-.4' }],
    ['path', { d: 'm13.4 8.3-.3.9' }],
  ],
];

export default ServerCog;
