import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name send
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMjIgMi03IDIwLTQtOS05LTRaIiAvPgogIDxwYXRoIGQ9Ik0yMiAyIDExIDEzIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/send
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Send: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm22 2-7 20-4-9-9-4Z' }],
    ['path', { d: 'M22 2 11 13' }],
  ],
];

export default Send;
