import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name trello
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHg9IjMiIHk9IjMiIHJ4PSIyIiByeT0iMiIgLz4KICA8cmVjdCB3aWR0aD0iMyIgaGVpZ2h0PSI5IiB4PSI3IiB5PSI3IiAvPgogIDxyZWN0IHdpZHRoPSIzIiBoZWlnaHQ9IjUiIHg9IjE0IiB5PSI3IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/trello
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 * @deprecated
 */
const Trello: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '18', height: '18', x: '3', y: '3', rx: '2', ry: '2' }],
    ['rect', { width: '3', height: '9', x: '7', y: '7' }],
    ['rect', { width: '3', height: '5', x: '14', y: '7' }],
  ],
];

export default Trello;
