import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name drafting-compass
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjUiIHI9IjIiIC8+CiAgPHBhdGggZD0ibTMgMjEgOC4wMi0xNC4yNiIgLz4KICA8cGF0aCBkPSJtMTIuOTkgNi43NCAxLjkzIDMuNDQiIC8+CiAgPHBhdGggZD0iTTE5IDEyYy0zLjg3IDQtMTAuMTMgNC0xNCAwIiAvPgogIDxwYXRoIGQ9Im0yMSAyMS0yLjE2LTMuODQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/drafting-compass
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const DraftingCompass: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '5', r: '2' }],
    ['path', { d: 'm3 21 8.02-14.26' }],
    ['path', { d: 'm12.99 6.74 1.93 3.44' }],
    ['path', { d: 'M19 12c-3.87 4-10.13 4-14 0' }],
    ['path', { d: 'm21 21-2.16-3.84' }],
  ],
];

export default DraftingCompass;
