import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name fish-symbol
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxNnM5LTE1IDIwLTRDMTEgMjMgMiA4IDIgOCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/fish-symbol
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const FishSymbol: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M2 16s9-15 20-4C11 23 2 8 2 8' }]],
];

export default FishSymbol;
