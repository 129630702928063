import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name clover
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTYuMTcgNy44MyAyIDIyIiAvPgogIDxwYXRoIGQ9Ik00LjAyIDEyYTIuODI3IDIuODI3IDAgMSAxIDMuODEtNC4xN0EyLjgyNyAyLjgyNyAwIDEgMSAxMiA0LjAyYTIuODI3IDIuODI3IDAgMSAxIDQuMTcgMy44MUEyLjgyNyAyLjgyNyAwIDEgMSAxOS45OCAxMmEyLjgyNyAyLjgyNyAwIDEgMS0zLjgxIDQuMTdBMi44MjcgMi44MjcgMCAxIDEgMTIgMTkuOThhMi44MjcgMi44MjcgMCAxIDEtNC4xNy0zLjgxQTEgMSAwIDEgMSA0IDEyIiAvPgogIDxwYXRoIGQ9Im03LjgzIDcuODMgOC4zNCA4LjM0IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/clover
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Clover: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M16.17 7.83 2 22' }],
    [
      'path',
      {
        d: 'M4.02 12a2.827 2.827 0 1 1 3.81-4.17A2.827 2.827 0 1 1 12 4.02a2.827 2.827 0 1 1 4.17 3.81A2.827 2.827 0 1 1 19.98 12a2.827 2.827 0 1 1-3.81 4.17A2.827 2.827 0 1 1 12 19.98a2.827 2.827 0 1 1-4.17-3.81A1 1 0 1 1 4 12',
      },
    ],
    ['path', { d: 'm7.83 7.83 8.34 8.34' }],
  ],
];

export default Clover;
