import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name cookie
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMmExMCAxMCAwIDEgMCAxMCAxMCA0IDQgMCAwIDEtNS01IDQgNCAwIDAgMS01LTUiIC8+CiAgPHBhdGggZD0iTTguNSA4LjV2LjAxIiAvPgogIDxwYXRoIGQ9Ik0xNiAxNS41di4wMSIgLz4KICA8cGF0aCBkPSJNMTIgMTJ2LjAxIiAvPgogIDxwYXRoIGQ9Ik0xMSAxN3YuMDEiIC8+CiAgPHBhdGggZD0iTTcgMTR2LjAxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/cookie
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Cookie: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 2a10 10 0 1 0 10 10 4 4 0 0 1-5-5 4 4 0 0 1-5-5' }],
    ['path', { d: 'M8.5 8.5v.01' }],
    ['path', { d: 'M16 15.5v.01' }],
    ['path', { d: 'M12 12v.01' }],
    ['path', { d: 'M11 17v.01' }],
    ['path', { d: 'M7 14v.01' }],
  ],
];

export default Cookie;
