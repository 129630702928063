import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name stamp
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNSAyMmgxNCIgLz4KICA8cGF0aCBkPSJNMTkuMjcgMTMuNzNBMi41IDIuNSAwIDAgMCAxNy41IDEzaC0xMUEyLjUgMi41IDAgMCAwIDQgMTUuNVYxN2ExIDEgMCAwIDAgMSAxaDE0YTEgMSAwIDAgMCAxLTF2LTEuNWMwLS42Ni0uMjYtMS4zLS43My0xLjc3WiIgLz4KICA8cGF0aCBkPSJNMTQgMTNWOC41QzE0IDcgMTUgNyAxNSA1YTMgMyAwIDAgMC0zLTNjLTEuNjYgMC0zIDEtMyAzczEgMiAxIDMuNVYxMyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/stamp
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Stamp: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M5 22h14' }],
    [
      'path',
      {
        d: 'M19.27 13.73A2.5 2.5 0 0 0 17.5 13h-11A2.5 2.5 0 0 0 4 15.5V17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-1.5c0-.66-.26-1.3-.73-1.77Z',
      },
    ],
    ['path', { d: 'M14 13V8.5C14 7 15 7 15 5a3 3 0 0 0-3-3c-1.66 0-3 1-3 3s1 2 1 3.5V13' }],
  ],
];

export default Stamp;
