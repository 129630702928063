import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name calendar-heart
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyAxMGgxOFY2YTIgMiAwIDAgMC0yLTJINWEyIDIgMCAwIDAtMiAydjE0YTIgMiAwIDAgMCAyIDJoNyIgLz4KICA8cGF0aCBkPSJNOCAydjQiIC8+CiAgPHBhdGggZD0iTTE2IDJ2NCIgLz4KICA8cGF0aCBkPSJNMjEuMjkgMTQuN2EyLjQzIDIuNDMgMCAwIDAtMi42NS0uNTJjLS4zLjEyLS41Ny4zLS44LjUzbC0uMzQuMzQtLjM1LS4zNGEyLjQzIDIuNDMgMCAwIDAtMi42NS0uNTNjLS4zLjEyLS41Ni4zLS43OS41My0uOTUuOTQtMSAyLjUzLjIgMy43NEwxNy41IDIybDMuNi0zLjU1YzEuMi0xLjIxIDEuMTQtMi44LjE5LTMuNzRaIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/calendar-heart
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const CalendarHeart: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 10h18V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7' }],
    ['path', { d: 'M8 2v4' }],
    ['path', { d: 'M16 2v4' }],
    [
      'path',
      {
        d: 'M21.29 14.7a2.43 2.43 0 0 0-2.65-.52c-.3.12-.57.3-.8.53l-.34.34-.35-.34a2.43 2.43 0 0 0-2.65-.53c-.3.12-.56.3-.79.53-.95.94-1 2.53.2 3.74L17.5 22l3.6-3.55c1.2-1.21 1.14-2.8.19-3.74Z',
      },
    ],
  ],
];

export default CalendarHeart;
