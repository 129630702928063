import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name hdmi-port
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjIgOWExIDEgMCAwIDAtMS0xSDNhMSAxIDAgMCAwLTEgMXY0YTEgMSAwIDAgMCAxIDFoMWwyIDJoMTJsMi0yaDFhMSAxIDAgMCAwIDEtMVoiIC8+CiAgPHBhdGggZD0iTTcuNSAxMmg5IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/hdmi-port
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const HdmiPort: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M22 9a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1l2 2h12l2-2h1a1 1 0 0 0 1-1Z' },
    ],
    ['path', { d: 'M7.5 12h9' }],
  ],
];

export default HdmiPort;
