import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name caravan
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iNCIgaGVpZ2h0PSI0IiB4PSIyIiB5PSI5IiAvPgogIDxyZWN0IHdpZHRoPSI0IiBoZWlnaHQ9IjEwIiB4PSIxMCIgeT0iOSIgLz4KICA8cGF0aCBkPSJNMTggMTlWOWE0IDQgMCAwIDAtNC00SDZhNCA0IDAgMCAwLTQgNHY4YTIgMiAwIDAgMCAyIDJoMiIgLz4KICA8Y2lyY2xlIGN4PSI4IiBjeT0iMTkiIHI9IjIiIC8+CiAgPHBhdGggZD0iTTEwIDE5aDEydi0yIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/caravan
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Caravan: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '4', height: '4', x: '2', y: '9' }],
    ['rect', { width: '4', height: '10', x: '10', y: '9' }],
    ['path', { d: 'M18 19V9a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v8a2 2 0 0 0 2 2h2' }],
    ['circle', { cx: '8', cy: '19', r: '2' }],
    ['path', { d: 'M10 19h12v-2' }],
  ],
];

export default Caravan;
