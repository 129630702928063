import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name fan
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAuODI3IDE2LjM3OWE2LjA4MiA2LjA4MiAwIDAgMS04LjYxOC03LjAwMmw1LjQxMiAxLjQ1YTYuMDgyIDYuMDgyIDAgMCAxIDcuMDAyLTguNjE4bC0xLjQ1IDUuNDEyYTYuMDgyIDYuMDgyIDAgMCAxIDguNjE4IDcuMDAybC01LjQxMi0xLjQ1YTYuMDgyIDYuMDgyIDAgMCAxLTcuMDAyIDguNjE4bDEuNDUtNS40MTJaIiAvPgogIDxwYXRoIGQ9Ik0xMiAxMnYuMDEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/fan
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Fan: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M10.827 16.379a6.082 6.082 0 0 1-8.618-7.002l5.412 1.45a6.082 6.082 0 0 1 7.002-8.618l-1.45 5.412a6.082 6.082 0 0 1 8.618 7.002l-5.412-1.45a6.082 6.082 0 0 1-7.002 8.618l1.45-5.412Z',
      },
    ],
    ['path', { d: 'M12 12v.01' }],
  ],
];

export default Fan;
