import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name pizza
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUgMTFoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xMSAxNWguMDEiIC8+CiAgPHBhdGggZD0iTTE2IDE2aC4wMSIgLz4KICA8cGF0aCBkPSJtMiAxNiAyMCA2LTYtMjBBMjAgMjAgMCAwIDAgMiAxNiIgLz4KICA8cGF0aCBkPSJNNS43MSAxNy4xMWExNy4wNCAxNy4wNCAwIDAgMSAxMS40LTExLjQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/pizza
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Pizza: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M15 11h.01' }],
    ['path', { d: 'M11 15h.01' }],
    ['path', { d: 'M16 16h.01' }],
    ['path', { d: 'm2 16 20 6-6-20A20 20 0 0 0 2 16' }],
    ['path', { d: 'M5.71 17.11a17.04 17.04 0 0 1 11.4-11.4' }],
  ],
];

export default Pizza;
