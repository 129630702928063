import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name dog
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgNS4xNzJDMTAgMy43ODIgOC40MjMgMi42NzkgNi41IDNjLTIuODIzLjQ3LTQuMTEzIDYuMDA2LTQgNyAuMDguNzAzIDEuNzI1IDEuNzIyIDMuNjU2IDEgMS4yNjEtLjQ3MiAxLjk2LTEuNDUgMi4zNDQtMi41IiAvPgogIDxwYXRoIGQ9Ik0xNC4yNjcgNS4xNzJjMC0xLjM5IDEuNTc3LTIuNDkzIDMuNS0yLjE3MiAyLjgyMy40NyA0LjExMyA2LjAwNiA0IDctLjA4LjcwMy0xLjcyNSAxLjcyMi0zLjY1NiAxLTEuMjYxLS40NzItMS44NTUtMS40NS0yLjIzOS0yLjUiIC8+CiAgPHBhdGggZD0iTTggMTR2LjUiIC8+CiAgPHBhdGggZD0iTTE2IDE0di41IiAvPgogIDxwYXRoIGQ9Ik0xMS4yNSAxNi4yNWgxLjVMMTIgMTdsLS43NS0uNzVaIiAvPgogIDxwYXRoIGQ9Ik00LjQyIDExLjI0N0ExMy4xNTIgMTMuMTUyIDAgMCAwIDQgMTQuNTU2QzQgMTguNzI4IDcuNTgyIDIxIDEyIDIxczgtMi4yNzIgOC02LjQ0NGMwLTEuMDYxLS4xNjItMi4yLS40OTMtMy4zMDltLTkuMjQzLTYuMDgyQTguODAxIDguODAxIDAgMCAxIDEyIDVjLjc4IDAgMS41LjEwOCAyLjE2MS4zMDYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/dog
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Dog: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M10 5.172C10 3.782 8.423 2.679 6.5 3c-2.823.47-4.113 6.006-4 7 .08.703 1.725 1.722 3.656 1 1.261-.472 1.96-1.45 2.344-2.5',
      },
    ],
    [
      'path',
      {
        d: 'M14.267 5.172c0-1.39 1.577-2.493 3.5-2.172 2.823.47 4.113 6.006 4 7-.08.703-1.725 1.722-3.656 1-1.261-.472-1.855-1.45-2.239-2.5',
      },
    ],
    ['path', { d: 'M8 14v.5' }],
    ['path', { d: 'M16 14v.5' }],
    ['path', { d: 'M11.25 16.25h1.5L12 17l-.75-.75Z' }],
    [
      'path',
      {
        d: 'M4.42 11.247A13.152 13.152 0 0 0 4 14.556C4 18.728 7.582 21 12 21s8-2.272 8-6.444c0-1.061-.162-2.2-.493-3.309m-9.243-6.082A8.801 8.801 0 0 1 12 5c.78 0 1.5.108 2.161.306',
      },
    ],
  ],
];

export default Dog;
