import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name utility-pole
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMnYyMCIgLz4KICA8cGF0aCBkPSJNMiA1aDIwIiAvPgogIDxwYXRoIGQ9Ik0zIDN2MiIgLz4KICA8cGF0aCBkPSJNNyAzdjIiIC8+CiAgPHBhdGggZD0iTTE3IDN2MiIgLz4KICA8cGF0aCBkPSJNMjEgM3YyIiAvPgogIDxwYXRoIGQ9Im0xOSA1LTcgNy03LTciIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/utility-pole
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const UtilityPole: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 2v20' }],
    ['path', { d: 'M2 5h20' }],
    ['path', { d: 'M3 3v2' }],
    ['path', { d: 'M7 3v2' }],
    ['path', { d: 'M17 3v2' }],
    ['path', { d: 'M21 3v2' }],
    ['path', { d: 'm19 5-7 7-7-7' }],
  ],
];

export default UtilityPole;
