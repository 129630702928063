import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name ampersands
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTdjLTUtMy03LTctNy05YTIgMiAwIDAgMSA0IDBjMCAyLjUtNSAyLjUtNSA2IDAgMS43IDEuMyAzIDMgMyAyLjggMCA1LTIuMiA1LTUiIC8+CiAgPHBhdGggZD0iTTIyIDE3Yy01LTMtNy03LTctOWEyIDIgMCAwIDEgNCAwYzAgMi41LTUgMi41LTUgNiAwIDEuNyAxLjMgMyAzIDMgMi44IDAgNS0yLjIgNS01IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/ampersands
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Ampersands: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M10 17c-5-3-7-7-7-9a2 2 0 0 1 4 0c0 2.5-5 2.5-5 6 0 1.7 1.3 3 3 3 2.8 0 5-2.2 5-5' },
    ],
    [
      'path',
      { d: 'M22 17c-5-3-7-7-7-9a2 2 0 0 1 4 0c0 2.5-5 2.5-5 6 0 1.7 1.3 3 3 3 2.8 0 5-2.2 5-5' },
    ],
  ],
];

export default Ampersands;
