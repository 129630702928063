import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name scroll-text
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOCAyMWgxMmEyIDIgMCAwIDAgMi0ydi0ySDEwdjJhMiAyIDAgMSAxLTQgMFY1YTIgMiAwIDEgMC00IDB2M2g0IiAvPgogIDxwYXRoIGQ9Ik0xOSAxN1Y1YTIgMiAwIDAgMC0yLTJINCIgLz4KICA8cGF0aCBkPSJNMTUgOGgtNSIgLz4KICA8cGF0aCBkPSJNMTUgMTJoLTUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/scroll-text
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ScrollText: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M8 21h12a2 2 0 0 0 2-2v-2H10v2a2 2 0 1 1-4 0V5a2 2 0 1 0-4 0v3h4' }],
    ['path', { d: 'M19 17V5a2 2 0 0 0-2-2H4' }],
    ['path', { d: 'M15 8h-5' }],
    ['path', { d: 'M15 12h-5' }],
  ],
];

export default ScrollText;
