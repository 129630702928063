import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name drill
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgOWMwIC42LS40IDEtMSAxSDRhMiAyIDAgMCAxLTItMlY0YTIgMiAwIDAgMSAyLTJoOWMuNiAwIDEgLjQgMSAxWiIgLz4KICA8cGF0aCBkPSJNMTggNmg0IiAvPgogIDxwYXRoIGQ9Ik0xNCA0aDNhMSAxIDAgMCAxIDEgMXYyYTEgMSAwIDAgMS0xIDFoLTMiIC8+CiAgPHBhdGggZD0ibTUgMTAtMiA4IiAvPgogIDxwYXRoIGQ9Ik0xMiAxMHYzYzAgLjYtLjQgMS0xIDFIOCIgLz4KICA8cGF0aCBkPSJtNyAxOCAyLTgiIC8+CiAgPHBhdGggZD0iTTUgMjJjLTEuNyAwLTMtMS4zLTMtMyAwLS42LjQtMSAxLTFoN2MuNiAwIDEgLjQgMSAxdjJjMCAuNi0uNCAxLTEgMVoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/drill
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Drill: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 9c0 .6-.4 1-1 1H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9c.6 0 1 .4 1 1Z' }],
    ['path', { d: 'M18 6h4' }],
    ['path', { d: 'M14 4h3a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-3' }],
    ['path', { d: 'm5 10-2 8' }],
    ['path', { d: 'M12 10v3c0 .6-.4 1-1 1H8' }],
    ['path', { d: 'm7 18 2-8' }],
    ['path', { d: 'M5 22c-1.7 0-3-1.3-3-3 0-.6.4-1 1-1h7c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1Z' }],
  ],
];

export default Drill;
