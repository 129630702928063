import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name pointer
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjIgMTRhOCA4IDAgMCAxLTggOCIgLz4KICA8cGF0aCBkPSJNMTggMTF2LTFhMiAyIDAgMCAwLTItMnYwYTIgMiAwIDAgMC0yIDJ2MCIgLz4KICA8cGF0aCBkPSJNMTQgMTBWOWEyIDIgMCAwIDAtMi0ydjBhMiAyIDAgMCAwLTIgMnYxIiAvPgogIDxwYXRoIGQ9Ik0xMCA5LjVWNGEyIDIgMCAwIDAtMi0ydjBhMiAyIDAgMCAwLTIgMnYxMCIgLz4KICA8cGF0aCBkPSJNMTggMTFhMiAyIDAgMSAxIDQgMHYzYTggOCAwIDAgMS04IDhoLTJjLTIuOCAwLTQuNS0uODYtNS45OS0yLjM0bC0zLjYtMy42YTIgMiAwIDAgMSAyLjgzLTIuODJMNyAxNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/pointer
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Pointer: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M22 14a8 8 0 0 1-8 8' }],
    ['path', { d: 'M18 11v-1a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v0' }],
    ['path', { d: 'M14 10V9a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v1' }],
    ['path', { d: 'M10 9.5V4a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v10' }],
    [
      'path',
      {
        d: 'M18 11a2 2 0 1 1 4 0v3a8 8 0 0 1-8 8h-2c-2.8 0-4.5-.86-5.99-2.34l-3.6-3.6a2 2 0 0 1 2.83-2.82L7 15',
      },
    ],
  ],
];

export default Pointer;
