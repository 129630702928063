import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name mails
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTMiIHg9IjYiIHk9IjQiIHJ4PSIyIiAvPgogIDxwYXRoIGQ9Im0yMiA3LTcuMSAzLjc4Yy0uNTcuMy0xLjIzLjMtMS44IDBMNiA3IiAvPgogIDxwYXRoIGQ9Ik0yIDh2MTFjMCAxLjEuOSAyIDIgMmgxNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/mails
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Mails: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '16', height: '13', x: '6', y: '4', rx: '2' }],
    ['path', { d: 'm22 7-7.1 3.78c-.57.3-1.23.3-1.8 0L6 7' }],
    ['path', { d: 'M2 8v11c0 1.1.9 2 2 2h14' }],
  ],
];

export default Mails;
