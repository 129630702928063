import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name bell-plus
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTkuMyAxNC44QzIwLjEgMTYuNCAyMSAxNyAyMSAxN0gzczMtMiAzLTljMC0zLjMgMi43LTYgNi02IDEgMCAxLjkuMiAyLjguNyIgLz4KICA8cGF0aCBkPSJNMTAuMyAyMWExLjk0IDEuOTQgMCAwIDAgMy40IDAiIC8+CiAgPHBhdGggZD0iTTE1IDhoNiIgLz4KICA8cGF0aCBkPSJNMTggNXY2IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/bell-plus
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BellPlus: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M19.3 14.8C20.1 16.4 21 17 21 17H3s3-2 3-9c0-3.3 2.7-6 6-6 1 0 1.9.2 2.8.7' }],
    ['path', { d: 'M10.3 21a1.94 1.94 0 0 0 3.4 0' }],
    ['path', { d: 'M15 8h6' }],
    ['path', { d: 'M18 5v6' }],
  ],
];

export default BellPlus;
