import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name club
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTcuMjggOS4wNWE1LjUgNS41IDAgMSAwLTEwLjU2IDBBNS41IDUuNSAwIDEgMCAxMiAxNy42NmE1LjUgNS41IDAgMSAwIDUuMjgtOC42WiIgLz4KICA8cGF0aCBkPSJNMTIgMTcuNjZMMTIgMjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/club
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Club: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M17.28 9.05a5.5 5.5 0 1 0-10.56 0A5.5 5.5 0 1 0 12 17.66a5.5 5.5 0 1 0 5.28-8.6Z' },
    ],
    ['path', { d: 'M12 17.66L12 22' }],
  ],
];

export default Club;
