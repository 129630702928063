import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name user-round-cog
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAyMWE4IDggMCAwIDEgMTAuNDM0LTcuNjIiIC8+CiAgPGNpcmNsZSBjeD0iMTAiIGN5PSI4IiByPSI1IiAvPgogIDxjaXJjbGUgY3g9IjE4IiBjeT0iMTgiIHI9IjMiIC8+CiAgPHBhdGggZD0ibTE5LjUgMTQuMy0uNC45IiAvPgogIDxwYXRoIGQ9Im0xNi45IDIwLjgtLjQuOSIgLz4KICA8cGF0aCBkPSJtMjEuNyAxOS41LS45LS40IiAvPgogIDxwYXRoIGQ9Im0xNS4yIDE2LjktLjktLjQiIC8+CiAgPHBhdGggZD0ibTIxLjcgMTYuNS0uOS40IiAvPgogIDxwYXRoIGQ9Im0xNS4yIDE5LjEtLjkuNCIgLz4KICA8cGF0aCBkPSJtMTkuNSAyMS43LS40LS45IiAvPgogIDxwYXRoIGQ9Im0xNi45IDE1LjItLjQtLjkiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/user-round-cog
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const UserRoundCog: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 21a8 8 0 0 1 10.434-7.62' }],
    ['circle', { cx: '10', cy: '8', r: '5' }],
    ['circle', { cx: '18', cy: '18', r: '3' }],
    ['path', { d: 'm19.5 14.3-.4.9' }],
    ['path', { d: 'm16.9 20.8-.4.9' }],
    ['path', { d: 'm21.7 19.5-.9-.4' }],
    ['path', { d: 'm15.2 16.9-.9-.4' }],
    ['path', { d: 'm21.7 16.5-.9.4' }],
    ['path', { d: 'm15.2 19.1-.9.4' }],
    ['path', { d: 'm19.5 21.7-.4-.9' }],
    ['path', { d: 'm16.9 15.2-.4-.9' }],
  ],
];

export default UserRoundCog;
