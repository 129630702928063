import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name swatch-book
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTdhNCA0IDAgMCAxLTggMFY1YTIgMiAwIDAgMSAyLTJoNGEyIDIgMCAwIDEgMiAyWiIgLz4KICA8cGF0aCBkPSJNMTYuNyAxM0gxOWEyIDIgMCAwIDEgMiAydjRhMiAyIDAgMCAxLTIgMkg3IiAvPgogIDxwYXRoIGQ9Ik0gNyAxN2gwLjAxIiAvPgogIDxwYXRoIGQ9Im0xMSA4IDIuMy0yLjNhMi40IDIuNCAwIDAgMSAzLjQwNC4wMDRMMTguNiA3LjZhMi40IDIuNCAwIDAgMSAuMDI2IDMuNDM0TDkuOSAxOS44IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/swatch-book
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const SwatchBook: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M11 17a4 4 0 0 1-8 0V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2Z' }],
    ['path', { d: 'M16.7 13H19a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H7' }],
    ['path', { d: 'M 7 17h0.01' }],
    [
      'path',
      { d: 'm11 8 2.3-2.3a2.4 2.4 0 0 1 3.404.004L18.6 7.6a2.4 2.4 0 0 1 .026 3.434L9.9 19.8' },
    ],
  ],
];

export default SwatchBook;
